import { render, staticRenderFns } from "./Divider.vue?vue&type=template&id=48d91800&scoped=true&"
var script = {}
import style0 from "./Divider.vue?vue&type=style&index=0&id=48d91800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d91800",
  null
  
)

export default component.exports