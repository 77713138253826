<template>
  <div class="customer-order-dropdowns__content-container">
    <div
    v-for="(container, index) in countContainers"
    :key="container"
    class="customer-order-dropdowns__content-container--photo-bag"
    @click="openExplorer(index)">
      <img v-if="!urlsImages[index]" class="camera-icon" src="@/assets/images/customer_order/camera-icon.svg" alt="camera icon">
      <p  v-if="!urlsImages[index]" class="title-container">Photo(s) of Bag#{{index + 1}}</p>
      <input type="file" ref="file" @change="handleFileUpload(index)" style="display: none">
      <img class="upload-photo" :src="urlsImages[index]">
    </div>
    <button @click="arrayUrlPhotos" class="customer-order-modal--upload-button">
      <div v-if="load">PHOTOS LOADING<div v-if="load" class="loader"></div></div>
      <p v-else>FINISH UPLOAD</p>
    </button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      file: '',
      urlsImages: [],
      images: [],
      load: false
    }
  },
  props: ['countContainers', 'customerInfo', 'orderInfo'],
  methods: {
    handleFileUpload (index) {
      this.file = this.$refs.file[index].files[0]

      const file123 = this.file
      const reader = new FileReader()

      let rawImg
      reader.onloadend = () => {
        rawImg = reader.result
        this.images[this.images.length] = rawImg
        // this.images[index] = rawImg
      }
      reader.readAsDataURL(file123)

      this.urlsImages[index] = URL.createObjectURL(this.file)
      this.urlsImages = [...this.urlsImages]
    },
    openExplorer (index) {
      this.$refs.file[index].click()
    },
    async arrayUrlPhotos () {
      // console.log(this.images)
      // console.log(this.urlsImages)
      // debugger // eslint-disable-line no-debugger
      // this.sendData()
      this.load = true
      await this.uploadImg()
      // this.$emit('arrayUrlPhotos', this.urlsImages.filter(el => el))
      // this.$forceUpdate()
      // this.$emit('arrayUrlPhotosGet')
    },
    async sendData () {
      const today = new Date()
      const totalObj = {
        customerId: this.customerInfo._id,
        orderNumber: this.orderInfo.orderNumber,
        orderInfo: {
          orderChangedDate: `${Date.parse(today)}`
        }
      }

      try {
        await fetch(
      `${process.env.VUE_APP_PROD_MODE === 'true'
        ? process.env.VUE_APP_API_SERVER
        : 'http://localhost:3100'}/update-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(totalObj)
      })
      } catch {
        alert('Something went wrong!')
      }
    },
    async uploadImg () {
      const today = new Date()
      const totalObj = {
        customerId: this.customerInfo._id,
        orderNumber: this.orderInfo.orderNumber,
        orderInfo: {
          orderChangedDate: `${Date.parse(today)}`
        },
        images: this.images
        // urlsImages: this.urlsImages
      }
      // console.log(totalObj)
      // debugger // eslint-disable-line no-debugger

      try {
        const response = await fetch(
      `${process.env.VUE_APP_PROD_MODE === 'true'
        ? process.env.VUE_APP_API_SERVER
        : 'http://localhost:3100'}/up-img`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(totalObj)
        // body: totalObj
      })
        if (response.status === 200) {
          this.load = false
          this.$emit('arrayUrlPhotosGet')
        } else {
          this.load = false
          alert('Something went wrong!')
        }
      } catch (error) {
        this.load = false
        alert('Something went wrong!')
      }
    }
  }
}
</script>

<style lang="scss">
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #00a9e0; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.customer-order-dropdowns__content-container {
  display: flex;
  flex-direction: column;
  &--photo-bag {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.5em;
      border: 2px dashed black;
      border-radius: 5px;
      background: white;
      .upload-photo {
        max-width: 95%;
        max-height: 10em;
      }
      .title-container {
          padding: 0.2em;
          font-weight: 600;
      }
  }
  .camera-icon {
      padding-top: 0.2em;
      max-width: 35px;
  }
}
</style>
