import $ from 'jquery'

export default {
  methods: {
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (re.test(String(email).toLowerCase())) {
        this.validEmail = true
      } else {
        this.validEmail = false
      }
    },
    addScrollToPopup () {
      $('.modal-content').css({
        'overflow-y': 'scroll',
        '-webkit-overflow-scrolling': 'touch'
      })
    },
    goToService (name, service) {
      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        },
        query: { orderOnDock: true }
      })
    }
  }
}
