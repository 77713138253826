<template>
  <div>
    <span>
      <select v-model="selected" class="input_full-width">
        <option :value="disOption" selected>{{ disOption }}</option>
        <option v-for="option in options" :value="option" :key="option">{{ option }}</option>
      </select>
    </span>
  </div>
</template>

<script>

export default {
  name: 'Select',
  props: ['disOption', 'count', 'selectedNum'],
  data: () => ({
    selected: '0'
  }),
  methods: {
    handleSelectValue (value) {
      if (isNaN(value)) {
        this.$emit('vue-selected', 0)
      } else {
        this.$emit('vue-selected', +value)
      }
    }
  },
  watch: {
    selected: {
      handler: function (val) {
        console.log(val)
        this.handleSelectValue(val)
      }
    }
  },
  computed: {
    options () {
      const numbers = []
      for (let i = 0; i < +this.count; i++) numbers[i] = i + 1
      numbers.push(`>${+this.count} contact us`)
      return numbers
    }
  },
  mounted () {
    if (this.selectedNum) this.selected = this.selectedNum
  }
}
</script>
