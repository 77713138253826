<template>
  <div class="change-order-info">
    <div class="change-order-info__title">
      <h2>YOUR ORDER:</h2>
      <h2>{{ changedOrderInfo.orderNumber }}</h2>
    </div>
    <div class="change-order-info-label">Contact Us</div>
    <div class="change-order-info-form">
      <h2>TO CHANGE OR CANCEL YOUR ORDER</h2>
      <div class="change-order-info-form--content">
        <label for="orderNumber">Order Number</label>
        <input
          type="text"
          name="orderNumber"
          v-model="changedOrderInfo.orderNumber"
          disabled
        >
        <label for="fullName">Full Name</label>
        <input
          type="text"
          name="fullName"
          v-model="changedCustomerInfo.customerName"
          disabled
        >
        <label for="message">Your Message*</label>
        <textarea
          name="message"
          rows="4"
          v-model="comment"
          class="change-order-info-form--content--comment"
        ></textarea>
        <div class="change-order-info-form--content--buttons">
          <button @click="close"><div></div> BACK</button>
          <button
            @click="sendData"
            :disabled="disabledButton"
            :class="{'change-order-info-form--content--buttons-disabled': disabledButton}"
          >CONTACT</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customerInfo: {
      type: Object,
      require: true
    },
    orderInfo: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    comment: '',
    contactForm: {
      orderNumber: '',
      name: '',
      email: '',
      comment: ''
    },
    changedOrderInfo: {},
    changedStorageInfo: {},
    changedCustomerInfo: {}
  }),
  mounted () {
    this.changedOrderInfo = Object.assign({}, this.orderInfo)
    this.changedCustomerInfo = Object.assign({}, this.customerInfo)
  },
  methods: {
    close () {
      this.$emit('closeChanges')
    },
    async sendData () {
      this.contactForm = {
        orderNumber: this.changedOrderInfo.orderNumber,
        name: this.changedCustomerInfo.customerName,
        email: this.customerInfo.customerEmail,
        comment: this.comment
      }
      if (this.comment !== '') {
        await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
        ? process.env.VUE_APP_API_SERVER // https://luggage-service-api.herokuapp.com - for production
        : 'http://localhost:3100'}/contact-us`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...this.contactForm })
        }).then(response => {
          if (response.status === 200) {
            this.close()
          } else {
            alert('Sorry, something went wrong!')
          }
        })
      } else alert('You did not fill in the field with the text')
    }
  },
  computed: {
    // disabledButton () {
    //   return !this.changedCustomerInfo.customerName || !this.changedCustomerInfo.customerComment
    // }
    disabledButton () {
      return !this.changedCustomerInfo.customerName
    }
  }
}
</script>

<style lang="scss">
$root: 'change-order-info';
$title: '#{$root}__title';
$label: '#{$root}-label';
$form: '#{$root}-form';

.change-order-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.#{$title} {
  width: 100%;
  margin-top: 90px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  h2 {
    color: rgb(73, 73, 73);
    font-weight: normal;
    font-size: 20px;
  }
}
.#{$label} {
  background-color: #FC9000;
  padding: 10px;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 7px 0 7px 0;
  width: 150px;
  margin: 10px 0 20px 5px;
  color: white;
  justify-self: start;
  margin-right: 50%;
  text-align: center;
  font-size: 20px;
}
.#{$form} {
  margin: 0 10px;
  width: 60% !important;
  min-width: 360px;
  h2 {
    color: rgb(73, 73, 73);
    font-weight: normal;
    font-size: 20px;
    text-align: center;
  }
  &--content {
    font-family: BlissMedium;
    background-color: #e9edef;
    border: 1px solid rgb(180, 180, 180);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    padding: 15px 15px 7px;
    input {
      color: rgb(73, 73, 73);
      width: 100%;
      font-size: 18px;
      margin-bottom: 20px;
    }
    label {
      color: rgb(73, 73, 73);
      font-size: 18px;
    }
    p {
      color: rgb(73, 73, 73);
      font-size: 18px;
    }
    &--phone {
      margin-bottom: 20px;
      font-size: 18px;
    }
    &--buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      button {
        padding: 7px 15px;
        border: 1px solid rgb(170, 170, 170);
        border-radius: 10px;
        cursor: pointer;
        font-size: 20px;
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 160px;
          div {
            font-size: 32px;
            margin-bottom: 7px;
            // padding: 0 5px 14px;
            // margin: -7px -7px -10px;
            &::after {
              content: '\2190';
            }
          }
        }
        &:first-child:active {
          background-color: #ffffff;
        }
        &:last-child {
          background-color: #FC9000;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
          color: white;
        }
        &:last-child:active {
          background-color: #eba548;
        }
      }
      &-disabled {
        opacity: 60%;
      }
    }
    &--comment {
      padding: 5px;
    }
  }
}

</style>
