<template>
  <div id="service" class="select_service">
    <div class="select">Need luggage transport or storage?</div>
    <div class="service">
      <div class="service-item">
        <a @click="goToService('Service', 'transport')">
          <div class="service-btn" onclick="">
            <img src="../assets/images/transport_btn.png" alt="transport icon" />
          </div>
          <div class="service-title">
            Luggage transport
          </div>
        </a>
      </div>
      <div class="service-item">
        <a @click="$root.$refs.Home.goToService('StorageInfo', 'storage')">
          <div class="service-btn">
            <img src="../assets/images/read_more/luggage.png" alt="storage icon" class="storage_btn" />
          </div>

          <div class="service-title">
            Luggage storage
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  computed: mapGetters(['getOrderInfo']),
  methods: {
    ...mapMutations(['addFromTo', 'goToMainPage']),
    goToService (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    }
  }
}
</script>

<style scoped>
  .service-btn {
    min-width: 82px;
    min-height: 82px;
    width: 82px;
    height: 82px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #53565A;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  }

  .service-btn img {
    width: 90%;
    height: 90%;
    margin: 0;
    padding: 0;
  }

  .storage_btn {
    width: 80% !important;
  }

  .service-item {
    margin: 20px 0;
    cursor: pointer;
    margin-right: 3.2em;
  }

  .service-title {
    font-family: BlissMedium;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: -0.05rem;
    margin: 0;
    padding: 0;
  }

  .select {
    height: auto;
    min-height: 50px;
    font-family: BlissLight;
    font-size: 20px;
    padding: 10px;
    letter-spacing: 0.07rem;
    text-transform: none;
  }

  .service {
    margin-left: 1.6em;
  }

  .service-item a {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 380px;
    width: 100%;
    justify-content: space-between;
  }

  .service-item:last-child .service-title {
    margin-right: 12px;
  }

  @media screen and (max-width: 414px) {
    .service-item a {
      max-width: 240px;
    }

    .service-title {
      max-width: 120px;
      text-align: center;
    }

    .service-item:last-child .service-title {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 318px) {
    .select {
      font-size: 18px;
    }
  }
</style>
