<template>
  <div class="home">
    <Header :isBook='!isBook' :isArrival='!getStorageInfo.storageDateEnd ? true : false'/>
    <div class="info-wrapper">
      <div class="delivery-info">
        <div class="delivery-info--content">
          <div class="orange-box">Delivery Info</div>
          <h3 class="direction">
            <slot v-if="!getStorageInfo.numberOfBags">{{ getOrderInfo.from }} to {{ getOrderInfo.to }}</slot>
            <slot v-if="getStorageInfo.numberOfBags">Storage</slot>
          </h3>
          <div class='container-info'><br v-if="getOrderInfo.to !== 'Airport'">
            <Popup
              v-if="getOrderInfo.to !== 'Airport'"
              :fieldName='
                (getOrderInfo.from === "Airport" && getOrderInfo.to === "Hotel") ? "Hotel name in City" :
                (getOrderInfo.to === "Hotel" ? "Hotel name in city" :
                (getOrderInfo.to === "Cruise" ? "Cruise ship name*" :
                (getOrderInfo.to === "Private Address" ? "Private address in city *" :
                (getStorageInfo.numberOfBags ? "Full Name *" : ""))))'
              :header='getOrderInfo.to === "Cruise" ? "Cruise ship name" : "Some delivery info"'
            />
            <input type="text" class="input_full-width" v-model="$data[getOrderInfo.to === 'Cruise' ? 'cruiseShip' :
                (getOrderInfo.to === 'Private Address' ? 'streetNumberPostal' : 'hotelName')]"
                v-if="getOrderInfo.to !== 'Airport'" v-on:change="info" required>
            <slot v-if="(getOrderInfo.to === 'Cruise' || getOrderInfo.to === 'Hotel' || getOrderInfo.to === 'Airport')">
              <br><br>
              <p>
                {{ getOrderInfo.to === 'Airport' ? "" : (getOrderInfo.to === 'Cruise' ? 'Stateroom (if known)' : '')}}
              </p>
              <Popup
                v-if="getOrderInfo.to === 'Airport'"
                :fieldName='getOrderInfo.to === "Airport" ? "Flight number (if known)" : ""'
                :header='"Some delivery info"'
              />
              <ArrivalNumber
                v-if="getOrderInfo.to !== 'Hotel'"
                :arrivalNumberId='getOrderInfo.to === "Airport" ? 1 :(getOrderInfo.to === "Cruise" ? 2 : 3)'
                :from='"ArrivalInfo"'
              />
            </slot>
            <slot v-if="(getOrderInfo.to === 'Private Address')"><br><br>
              <p>Apartment identification</p>
              <input
                type="text"
                class="input_full-width"
                v-model="apartment"
                @change="info"
                placeholder="e.g. name on door, floor, …"
                required
              ><br>
            </slot>
          </div><br>
          <div><img src="../assets/images/we_deliver.png"></div><br>
          <div class="buttons-container">
            <button @click="
                    $route.query.orderOnDock ? goToService('ArrivalInfo', service) :
                    !getStorageInfo.numberOfBags ? goTo('ArrivalInfo', service) :
                    goTo('StorageInfo', service)">Back</button>
            <button class="btn-next" :disabled="(getOrderInfo.to === 'Cruise' ? !cruiseShip :
                    (getOrderInfo.to === 'Private Address' ? !apartment && !streetNumberPostal :
                    (getOrderInfo.to === 'Hotel' || getStorageInfo.numberOfBags ? !hotelName :
                    (getOrderInfo.to === 'Airport' ? !getValidator.isValidNumber : ''))))"
                    @click="$route.query.orderOnDock ? goToService('TravellerInfo', service) : goTo('TravellerInfo', service)">Next</button>
          </div>
        </div>
      </div>
      <BasketInfo :isArrival='!getStorageInfo.storageDateEnd ? true : false' class="basket-info-hidden" />
    </div>
    <Links link-location="row" />
    <ContactUs />
  </div>
</template>

<script>
import additionalFunctions from '../mixins/additionalFunctions'
import Header from '@/components/Header'
import ArrivalNumber from '@/components/ArrivalNumber'
import Popup from '@/components/Popup'
import Links from '@/components/TCAndPrivacyPolicyLinks'
import ContactUs from '@/components/ContactUs'
import BasketInfo from '@/components/BasketInfo'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DeliveryInfo',
  props: ['service'],
  components: {
    Header,
    ArrivalNumber,
    Popup,
    ContactUs,
    BasketInfo,
    Links
  },
  computed: mapGetters(['getOrderInfo', 'getStorageInfo', 'getValidator']),
  mixins: [additionalFunctions],
  data () {
    return {
      isBook: true,
      hotelName: '',
      streetNumberPostal: '',
      apartment: '',
      cruiseShip: ''
    }
  },
  mounted () {
    this.goToMainPage()
  },
  created () {
    for (const key in this.getOrderInfo) {
      if (key in this._data) {
        this[key] = this.getOrderInfo[key]
      }
    }
  },
  methods: {
    ...mapMutations(['addFieldsForDeliveryInfo', 'goToMainPage', 'addFromTo']),
    info () {
      for (const key in this.getOrderInfo) {
        if (key in this.getOrderInfo && (this[key] || this[key] === '')) {
          this.addFieldsForDeliveryInfo({
            [key]: this[key]
          })
        }
      }
    },
    goTo (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  input {
    height: 40px;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 65%;
  }

  @media screen and (min-width: 1023px) {
    img {
      width: 40%;
    }
  }

  .container-info {
    padding: 5px 15px 50px 15px;
  }

  .delivery-info--content {
    margin: 110px 20px 0;
    padding-bottom: 30px;
  }
</style>
