<template>
  <div class="basket-info">
    <h2>
      <span class="close-btn" @click="modalControl(false)">X</span>
      BASKET
    </h2>
    <h3>Your order details:</h3>
    <p class="basket-p bold small-pad"><br>Service</p>
    <span v-if="getOrderInfo.from && getOrderInfo.to" class="basket-span">Luggage transport from {{ getOrderInfo.from }} to {{ getOrderInfo.to }}</span>
    <span v-else class="basket-span">
      Storage
      <span v-if="getAllBagsCount(isArrival) === 1">for {{ getAllBagsCount(isArrival) }} bag</span>
      <span v-if="getAllBagsCount(isArrival) > 1">for {{ getAllBagsCount(isArrival) }} bags</span>
      <span v-if="getStorageDays === 1"> for {{ getStorageDays }} day</span>
      <span v-if="getStorageDays > 1"> for {{ getStorageDays }} days</span>
    </span>
    <slot v-if="getOrderInfo.arrivalDate || getStorageInfo.storageDateFrom ||
                getOrderInfo.arrivalTime || getStorageInfo.storageTimeFrom">
      <p v-if="getOrderInfo.from === 'Airport'" class="basket-p"><br>Arrival at {{ getOrderInfo.from }}</p>
      <p v-else-if="getOrderInfo.to === 'Airport'" class="basket-p"><br>Luggage hand out in {{ getOrderInfo.to }}</p>
      <p
        v-else-if="(getOrderInfo.from !== 'Hotel' && getOrderInfo.to !== 'Cruise') && (getOrderInfo.from !== 'Cruise' && getOrderInfo.to !== 'Hotel')"
        class="basket-p"
      >
        <br>Storage start time:
      </p>
      <span
        class="basket-span"
        v-if="(getOrderInfo.from !== 'Hotel' || getOrderInfo.to !== 'Cruise') && (getOrderInfo.from !== 'Cruise' || getOrderInfo.to !== 'Hotel')"
      >
        {{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate :
          getStorageInfo.storageDateFrom
        }}<br>
        At {{ getOrderInfo.arrivalTime ? getOrderInfo.arrivalTime : getStorageInfo.storageTimeFrom }}<br>
      </span>
      <p class="basket-p" v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Hotel'">
        <br><br>Delivery location:
        <span class="basket-span"><br>{{ getOrderInfo.hotelName }}<br><br></span>
        <span class="basket-span" v-if="getOrderInfo.hotelName">
          We will deliver your bags to the hotel reception by 3 pm (for arrivals before 10 am)
        </span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Cruise'">
        <br><br>Delivery location:
        <span class="basket-span"><br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}</span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Private Address'">
        <br><br>Delivery location:
        <span class="basket-span">
          <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
          <br>2 days before delivery, you will get a 2-hour time slot for delivery at your address
        </span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Airport'">
        <br><br>Pick-up location:
        <span class="basket-span">
          <br>{{ getOrderInfo.hotelName }} {{ getOrderInfo.hotelroom ? '(' + getOrderInfo.hotelroom + ')' : '' }}
          <br>Leave bags at the reception by 8.30 am
        </span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Airport'">
        <br><br>Pick-up location:
        <span class="basket-span"><br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}</span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Private Address' && getOrderInfo.to === 'Airport'">
        <br><br>Pick-up location:
        <span class="basket-span">
          <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
          <br>2 days before pick-up, you will get a 2-hour time slot for pick-up at your address, 6.30 am - 11 am
        </span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Cruise'">
        <br><br>Pick-up location:
        <span class="basket-span">
          <br>{{ getOrderInfo.hotelName }}
          <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
          <br>Leave bags at the reception by 8.30 am
        </span>
        <br><br>Delivery location:
        <span class="basket-span"><br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}</span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Hotel'">
        <br><br>Pick-up location:
        <span class="basket-span">
          <br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}
          <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
        </span>
        <br><br>Delivery location:
        <span class="basket-span"><br>{{ getOrderInfo.hotelName }}<br><br></span>
        <span class="basket-span" v-if="getOrderInfo.hotelName">
          We will deliver your bags to the hotel reception by 3 pm (for arrivals before 10 am)
        </span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Private Address'">
        <br><br>Pick-up location:
        <span class="basket-span">
          <br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}
          <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
        </span>
        <br><br>Delivery location:
        <span class="basket-span">
          <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
          <br>2 days before delivery, you will get a 2-hour time slot for delivery at your location.
        </span>
      </p>
      <p class="basket-p" v-if="getOrderInfo.from === 'Private Address' && getOrderInfo.to === 'Cruise'">
        <br><br>Pick-up location:
        <span class="basket-span">
          <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
          <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
        </span>
        <br><br>Delivery location:
        <span class="basket-span">
          <br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}
          <br>2 days before delivery, you will get a 2-hour time slot for pick-up from your location.
        </span>
      </p>
    </slot>
    <slot v-if="getStorageInfo.storageDateEnd || getStorageInfo.storageTimeEnd">
      <p class="basket-p"><br><br>Storage end time:</p>
      <span class="basket-span">
        {{ getStorageInfo.storageDateEnd }}<br>
        At {{ getStorageInfo.storageTimeEnd }}<br>
      </span>
    </slot>
    <hr>
    <p class="basket-p"><strong>Price</strong><br></p>
    <div class="basket-span" v-if="getStorageInfo.numberOfBags || getStorageInfo.numberOfHeavyBags">
      <span>1st night,</span>
      <span v-if="getAllBagsCount(isArrival) === 1"> {{ getAllBagsCount(isArrival) }} bag,</span>
      <span v-if="getAllBagsCount(isArrival) > 1"> {{ getAllBagsCount(isArrival) }} bags,</span>
      <span> EUR {{ getStateData.additionalInfo.bagsStoragePriceFirstNight }}</span>
    </div>
    <div class="basket-span" v-if="!getStorageInfo.numberOfBags && !getStorageInfo.numberOfHeavyBags">
      {{ getAllBagsCount(isArrival) }} bag(s), EUR {{ getStateData.additionalInfo.bagsPrice }}
    </div>
    <span class="basket-span italic" v-if="$route.path === '/storage-info' || getStorageInfo.numberOfBags || getStorageInfo.numberOfHeavyBags">
      <span v-if="getAllBagsCount(isArrival) < getStateData.constants.MIN_BAGS_STORAGE">1st night min. EUR {{ getStateData.constants.MIN_PRICE_STORAGE }}</span>
      <span v-else> EUR {{ getStateData.constants.VALUE1_STORAGE }} per bag</span>
      <br>
    </span>
    <span class="basket-span italic"
          v-if="getOrderInfo.from === 'Private Address' || getOrderInfo.to === 'Private Address'">
      EUR {{ getStateData.constants.VALUE1_PRIVATE }} pr. bag</span>
    <template v-if="!(getUrlPrices.bagPrice || getUrlPrices.bagHeavyPrice)">
      <span class="basket-span italic"
            v-if="getOrderInfo.from !== 'Private Address' && getOrderInfo.to !== 'Private Address' &&
            ($route.path !== '/storage-info' && !getStorageInfo.numberOfBags && !getStorageInfo.numberOfHeavyBags)">
            EUR {{ getStateData.constants.VALUE1_AIRPORT }} pr. bag</span>
      <span class="basket-span italic" v-if="$route.path !== '/storage-info' && !getStorageInfo.numberOfBags && !getStorageInfo.numberOfHeavyBags && (getAllBagsCount(isArrival)<getStateData.constants.MIN_BAGS_TRANSFER)">,
       min. price EUR {{ getOrderInfo.from === 'Private Address' || getOrderInfo.to === 'Private Address' ? getStateData.constants.MIN_PRICE_PRIVATE : getStateData.constants.MIN_PRICE }}
      </span>
    </template>
    <template v-else>
      <span class="basket-span italic">
        EUR {{ getUrlPrices.bagPrice || getStateData.constants.VALUE1_AIRPORT}}/bag
      </span>
    </template>
    <div class="basket-span"
      v-if="getStorageInfo.numberOfHeavyBags > 0 || getOrderInfo.numberOfHeavyBags > 0">
      {{ getStorageInfo.numberOfHeavyBags > 0 ?
        getStorageInfo.numberOfHeavyBags : getOrderInfo.numberOfHeavyBags }} odd-size, EUR
      {{ getStateData.additionalInfo.oddSizeBagsPrice }}
    </div>
    <template v-if="!(getUrlPrices.bagPrice || getUrlPrices.bagHeavyPrice)">
    <span class="basket-span italic"
          v-if="getStorageInfo.numberOfHeavyBags > 0 || getOrderInfo.numberOfHeavyBags > 0">
      One time handling fee is EUR {{ getUrlPrices.bagHeavyPrice || getStateData.constants.VALUE2 }}/odd-size<br>
    </span>
    </template>
    <template v-else>
    <span class="basket-span italic"
          v-if="getStorageInfo.numberOfHeavyBags > 0 || getOrderInfo.numberOfHeavyBags > 0">
      One time handling fee is € {{ getUrlPrices.bagHeavyPrice || getStateData.constants.VALUE2 }}/odd-size<br>
    </span>
    </template>
    <div v-if="(getStorageInfo.numberOfBags || getStorageInfo.numberOfHeavyBags)  && getStorageDays > 1">
      <div class="basket-span">
        {{ getStorageDays - 1 }} extra night(s) EUR {{ getStateData.additionalInfo.bagsPrice - getStateData.additionalInfo.bagsStoragePriceFirstNight }}
      </div>
      <span class="basket-span italic">
        EUR {{getStateData.constants.VALUE1_STORAGE}} per bag per night<br>
        <span v-if="getStorageDays > 7">
          (EUR {{getStateData.constants.VALUE1_STORAGE2}} after day {{getStateData.constants.VALUE1_DAYLIMIT}})
        </span>
      </span>
    </div>
    <div class="basket-span" v-if="getStateData.additionalInfo.lateFee">
      Late ordering fee, EUR {{ getStateData.additionalInfo.lateFee }}
    </div>
    <span class="basket-span italic" v-if="getStateData.additionalInfo.lateFee">
      Handling fee for orders placed 2 days or less before service day<br><br>
    </span>
    <h3 class="total-price" v-if="priceCalculation(isArrival) > 0">Total Price<br></h3>
    <div class="basket-p mt" v-if="priceCalculation(isArrival) > 0">
      <strong>EUR {{ priceCalculation(isArrival) }}</strong>
    </div>
    <div class="basket-span italic black" v-if="priceCalculation(isArrival) > 0">
      Including 25% VAT and credit card fee
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['isArrival'],
  computed: {
    ...mapGetters(['getUrlPrices', 'getOrderInfo', 'getStorageInfo', 'getStateData', 'getAllBagsCount', 'priceCalculation', 'getStorageDays']),
    isSafari () {
      return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification))
    }
  }
}
</script>

<style lang="scss">
$basket-info: 'basket-info';

.#{$basket-info} {
  h2 {
    color: #53565A;
    font-family: 'BlissLight';
    font-weight: 500;
    letter-spacing: .2rem;
    text-align: center;
    font-size: 22px;
    padding-bottom: 10px;
    .close-btn {
      float: left;
    }
  }
  h3 {
    margin-top: 20px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 10px;
  }
  hr {
    margin: 2em .2em 2em .2em;
    display: block;
    height: 1px;
    border-top: 1px solid #ccc;
  }
  min-width: 300px;
  width: 300px;
  border-left: 2px solid rgb(170, 170, 170);
  padding: 20px 10px 0;
  margin: 95px 20px 0 0;
  @media (min-width: 1023px) {
    border-right: 2px solid rgb(170, 170, 170);
    padding: 20px 10px;
  }
  .basket-content {
    padding-right: 10px;
  }
  .basket-span.black {
    color: rgb(26, 26, 26);
    font-family: 'BlissMedium';
    letter-spacing: 0.02rem;
  }
  .basket-p {
    font-family: BlissLight;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 0.07rem;
    color:#000000;
    text-transform: uppercase;
  }
  .basket-span {
    font-family: BlissLight;
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 0.07rem;
    text-transform: initial;
    color: #53565A;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .italic {
    font-style: italic;
    color: #797b7e;
    line-height: 20px;
    @media (min-width: 800px) {
      font-size: 13px;
    }
  }
  .basket-span::after {
    content: "";
  }
}
</style>
