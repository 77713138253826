<template>
  <div class="tc-and-privacy-links">
    <div
      v-if="linkLocation === 'row'"
      class="tc-and-privacy-links__row"
      :class="($route.path === '/' || $route.path === '/service')
        ? 'tc-and-privacy-links__row--for-readmore'
        : 'tc-and-privacy-links__row--for-infopages'"
    >
      <span class="tc-and-privacy-links__row--link" @click="openModal('terms')">Terms & Conditions</span>
      <span class="tc-and-privacy-links__row--link" @click="openModal('policy')">Privacy Policy</span>
    </div>
    <div v-else class="tc-and-privacy-links__column">
      <ul>
        <li>
          <span class="tc-and-privacy-links__column--li" @click="openModal('terms')">See our Terms & Conditions here</span>
        </li>
        <li>
          <span class="tc-and-privacy-links__column--li" @click="openModal('policy')">See our Privacy Policy here</span>
        </li>
      </ul>
    </div>
    <transition name="slide" appear>
      <div
        class="modal"
        :class="linkLocation === 'column' ? 'modal-full' : ''"
        v-if="showModal"
      >
        <span class="close-btn" @click="showModal = false">X</span>
        <h2 v-html="title"></h2>
        <div class="modal-content tc-and-privacy-links--content" v-html="description"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TCAndPrivacyPolicyLinks',
  props: {
    linkLocation: {
      type: String
    }
  },
  async created () {
    if (!this.getPrivacyPolicyContent) {
      const response = await fetch('https://www.copenhagenluggageservice.com//wp-json/wp/v2/pages/5036', {
        method: 'GET',
        headers: new Headers({ 'content-type': 'application/json;charset=utf-8' })
      })
      const result = await response.json()
      this.setPrivacyPolicyContent(result)
    }
    if (!this.getTermsAndConditionsContent) {
      const response = await fetch('https://www.copenhagenluggageservice.com//wp-json/wp/v2/pages/5000', {
        method: 'GET',
        headers: new Headers({ 'content-type': 'application/json;charset=utf-8' })
      })
      const result = await response.json()
      this.setTermsAndConditionsContent(result)
    }
  },
  computed: {
    ...mapGetters(['getPrivacyPolicyContent', 'getTermsAndConditionsContent', 'getCloseAllPopups']),
    policyTitle () {
      if (this.getPrivacyPolicyContent.title) {
        return this.getPrivacyPolicyContent.title.rendered
      }
      return ''
    },
    policyContent () {
      if (this.getPrivacyPolicyContent.content) {
        return this.getPrivacyPolicyContent.content.rendered.replace(/ *\[[^)]*\] */g, ' ').trim()
      }
      return ''
    },
    termsTitle () {
      if (this.getTermsAndConditionsContent.title) {
        return this.getTermsAndConditionsContent.title.rendered
      }
      return ''
    },
    termsContent () {
      if (this.getTermsAndConditionsContent.content) {
        const content = this.getTermsAndConditionsContent.content.rendered.replace(/ *\[[^)]*\] */g, ' ').trim()
        const index = content.indexOf('GENERAL')
        const result = content.substring(index - 12)
        return result
      }
      return ''
    }
  },
  data: () => ({
    showModal: false,
    title: '',
    description: ''
  }),
  methods: {
    ...mapMutations(['setPrivacyPolicyContent', 'setTermsAndConditionsContent']),
    async openModal (type) {
      this.showModal = true
      this.title = type === 'terms' ? this.termsTitle : this.policyTitle
      this.description = type === 'terms' ? this.termsContent : this.policyContent
    }
  },
  watch: {
    showModal (newVal, oldVal) {
      if (newVal) {
        document.querySelector('html').style.overflow = 'hidden'
      } else {
        document.querySelector('html').style.overflow = 'auto'
      }
    },
    getCloseAllPopups (newVal, oldVal) {
      if (newVal === true) {
        this.showModal = false
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/popup";

$root: 'tc-and-privacy-links';
$row: '#{$root}__row';
$column: '#{$root}__column';

.#{$root} {
  &--content {
    font-family: BlissLight;
    color: #666;
    font-size: 18px;
    font-weight: 400;
    ol {
      margin: 15px 25px;
    }
    li {
      margin-top: 5px;
    }
    & > * + * {
      margin-bottom: 15px;
      line-height: 22px;
    }
  }
}
.#{$row} {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &--link {
    font-family: BlissLight;
    padding: 5px;
    text-decoration: underline;
    cursor: pointer;
    background-color: white;
    &:first-child {
      border-radius: 0 5px 0 0;
    }
    &:last-child {
      border-radius: 5px 0 0 0;
    }
    @media (min-width: 1023px) {
      &:first-child {
      border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 5px 5px 0 0;
      }
    }
  }
  &--for-readmore {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    @media (min-width: 860px) {
      padding: 0 20px;
    }
  }
  &--for-infopages {
    @media (min-width: 1490px) {
      margin-left: calc(15% + 35px);
      max-width: 680px;
    }
    @media (min-width: 1023px) and (max-width: 1490px) {
      margin-right: calc(15% + 335px);
      margin-left: calc(15% + 35px);
    }
    @media (min-width: 800px) and (max-width: 1023px) {
      margin-right: 335px;
      margin-left: 10px;
    }
    @media (max-width: 800px) {
      margin: 0 10px;
    }
  }
}
.#{$column} {
  padding-left: 15px;
  li {
    padding-bottom: 5px;
    cursor: pointer;
  }
 &--li {
    color: #3da1de;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 0.1rem;
    text-decoration: none;
 }
}
</style>
