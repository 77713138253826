<template>
  <div>
   <input
    class="input_full-width"
    type="text"
    v-model="$data[arrivalNumberId === 1 ? 'flightNumber' :
      arrivalNumberId === 0 ? 'flightNumber2' :
      (arrivalNumberId === 2 ? 'stateroom' : (arrivalNumberId === 3 ? 'hotelroom' : 'hotelName'))]"
    @blur="arrivalNumberId === 1 ? validateArrivalNumber(flightNumber) :
      arrivalNumberId === 0 ? validateArrivalNumber(flightNumber2) :
      (arrivalNumberId === 2 ? validateArrivalNumber(stateroom) :
      (arrivalNumberId === 3 ? validateArrivalNumber(hotelroom) : validateArrivalNumber(hotelName)))"
            :placeholder="arrivalNumberId !== 2 && arrivalNumberId !== 3 && arrivalNumberId !== 4 ? 'e.g. DF4092' : ''"
            v-on:change="number" :class="isValidNumber ? '' : 'invalid'">
    <small v-if="isValidNumber ? '' : 'invalid'">{{ validateFlightNumText }}</small><br>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ArrivalNumber',
  props: ['arrivalNumberId', 'from'],
  computed: mapGetters(['getOrderInfo', 'getStorageInfo']),
  data () {
    return {
      flightNumber: '',
      flightNumber2: '',
      stateroom: '',
      hotelroom: '',
      hotelName: '',
      isValidNumber: true,
      validateFlightNumText: 'Please enter only numbers and/or letters without spaces'
    }
  },
  created () {
    if (this.arrivalNumberId === 2) {
      this.stateroom = this.getOrderInfo.stateroom
    }

    if (this.arrivalNumberId === 3) {
      this.hotelroom = this.getOrderInfo.hotelroom
    }

    if (this.arrivalNumberId === 4) {
      this.hotelName = this.getOrderInfo.hotelName
    }

    if ((this.arrivalNumberId === 1 || this.arrivalNumberId === 0) && this.from === 'ArrivalInfo') {
      this.flightNumber = this.getOrderInfo.flightNumber
    } else {
      this.flightNumber = this.getStorageInfo.flightNumber
      this.flightNumber2 = this.getStorageInfo.flightNumber2
    }
  },
  methods: {
    ...mapMutations(['addArrivalNumber', 'validator', 'changeHotelName']),
    number () {
      if (this.arrivalNumberId === 2) {
        this.addArrivalNumber({
          stateroom: this.stateroom
        })
      }

      if (this.arrivalNumberId === 3) {
        this.addArrivalNumber({
          hotelroom: this.hotelroom
        })
      }

      if (this.arrivalNumberId === 4) {
        this.addArrivalNumber({
          hotelName: this.hotelName
        })
      }

      if ((this.arrivalNumberId === 1 || this.arrivalNumberId === 0) && this.from === 'ArrivalInfo') {
        this.addArrivalNumber({
          flightNumber: this.flightNumber
        })
      } else {
        if (this.getStorageInfo.flightNumber) {
          this.flightNumber = this.getStorageInfo.flightNumber
        }
        if (this.getStorageInfo.flightNumber2) {
          this.flightNumber2 = this.getStorageInfo.flightNumber2
        }
        this.addArrivalNumber({
          flightNumberStorage: this.flightNumber,
          flightNumberStorage2: this.flightNumber2
        })
      }
    },
    validateArrivalNumber (dataStr) {
      if (this.arrivalNumberId === 4) {
        this.isValidNumber = true
        this.validator(true)
        return ''
      } else if (this.arrivalNumberId === 2) {
        this.isValidNumber = true
        this.validator(true)
        return ''
      } else if (dataStr.match(/^[0-9a-zA-Z]+$/) || dataStr === '') {
        this.isValidNumber = true
        this.validator(true)
        return ''
      } else {
        this.isValidNumber = false
        this.validator(false)
        return 'invalid'
      }
    }
  },
  watch: {
    hotelName: {
      handler: function (val) {
        this.changeHotelName(val)
      }
    },
    flightNumber: {
      handler: function (val) {
        this.addArrivalNumber({
          flightNumberStorage: val
        })
      }
    },
    flightNumber2: {
      handler: function (val) {
        this.addArrivalNumber({
          flightNumberStorage2: val
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  div > input[type=text] {
    margin-top: 10px !important;
    height: 40px;
  }
</style>
