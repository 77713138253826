<template>
  <div class="home">
    <Header :isBook='isBook' :isArrival='false'/>
    <div class="intro">
      <div class="first-block">
        <h1>Copenhagen<br> Luggage Service</h1>
        <hr>
        <h3>Do you need a relaxing travel without luggage-hassle?</h3>
        <img src="../assets/images/girl.png">
      </div>
      <div class="tagline"><p>You travel<br> we carry</p></div>
      <div class="we_offer">
        <img src="../assets/images/flang_car.png">
        <img src="../assets/images/we_offer_changed.png">
      </div>
      <div class="few_steps">
        <p>Order in a few steps <img class="few_steps_img" src="../assets/images/steps.png"></p>
        <img class="few_steps_second-img" src="../assets/images/arrow.png">
        <p>ARE YOU LOOKING FOR<br> Luggage Transport or Storage?</p>
      </div>
      <!-- <div v-if="isTransport"><TransportService /></div> -->
      <div class="service-wrapper">
        <SelectService @openTransport="setTransport" />
      </div>
      <!-- <div id="service" class="select_service">
        <div class="select">Select service</div>
        <div class="service">
          <div class="service-btn" onclick="">Transport</div>
          <div class="service-content"><a>Luggage from...</a></div>
          <div v-for="service in getOrderInfo.services" :key="service" class="service-content">
            <a @click="goToService('Service', service)" v-if='service'>- {{ service }}</a>
          </div>
        </div>
        <a @click="goToService('StorageInfo')">
          <div class="service-btn">Storage</div>
        </a>
      </div> -->
      <ReadMore />
    </div>
    <Links link-location="row" />
    <ContactUs />
  </div>
</template>

<script>
import Header from '@/components/Header'
import ReadMore from '@/components/ReadMore'
import Links from '@/components/TCAndPrivacyPolicyLinks'
import ContactUs from '@/components/ContactUs'
import SelectService from '@/components/SelectService'
// import TransportService from '@/components/TransportService'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    ReadMore,
    Links,
    ContactUs,
    SelectService
  },
  computed: mapGetters(['getOrderInfo']),
  created () {
    this.$root.$refs.Home = this
    // console.log('url = ' + window.location.search) // this.$route.query.priceHeavyBags
    // console.log('test url: ' + this.$route.query.skipPayment)
    this.addNewPriceFromUrl({
      minimumPrice: this.$route.query.minPrice,
      skipPayment: this.$route.query.skipPayment,
      todayBookingAllowed: typeof this.$route.query.todayBookingAllowed === 'undefined' ? 0 : this.$route.query.todayBookingAllowed // true  todayBookingAllowed
    })
    // console.log('queryprice skippayment = ' + this.queryPrices.skipPayment)
    this.pingpong()
  },
  data () {
    return {
      isBook: true,
      isTransport: false
    }
  },
  mounted () {
    if ((this.$route.query.from && this.$route.query.to) || this.$route.query.go) {
      this.goToServiceFromHomepage()
    }
  },
  methods: {
    ...mapMutations(['addNewPriceFromUrl']),
    async pingpong () {
      const response = await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER
          : 'http://localhost:3100'}/ping`)

      const { message } = await response.json()
      console.log(message)
    },

    ...mapMutations(['addFromTo', 'goToMainPage']),
    goToService (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    },
    // if go to this page from main app
    goToServiceFromHomepage () {
      if (this.$route.query.go === 'storageInfo') {
        this.$router.push('/storage-info')
      } else {
        this.addFromTo({
          from: this.$route.query.from,
          to: this.$route.query.to
        })
        this.$router.push('/arrival-info')
      }
    },
    setTransport (state) {
      this.isTransport = state
    }
  }
}
</script>

<style lang="scss">

  #service {
    min-height: 320px;
  }
  .service-btn.from {
    display: block;
    border-radius: 10px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
  }

  .service-title {
    text-align: left;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 2px;
    font-family: BlissLight;
  }

  #service .service-content {
    display: block;
  }

  .service-items .arrow {
    transform: rotateZ(90deg);
    transition: .25s;
  }

  .arrow.is_open {
    transform: rotateZ(-90deg);
  }

  .delivery-info .orange-box {
    font-size: 16px;
  }

  .arrow {
    transform: rotateZ(90deg);
    transition: .25s;
  }

  .intro {
    margin: 5em auto 0;
    padding: 3em 0 0;
    min-height: calc(100vh - 147px);
    width: 100%;
    max-width: 1024px;
  }

  .intro img {
    max-width: 280px;
    height: auto;
    margin: 2em auto 2em auto;
    display: block;
  }

  @media screen and (max-width: 320px) {
    .intro img {
      max-width: 280px;
    }
  }

  @media screen and (max-width: 280px) {
    .intro img {
        max-width: 240px;
    }
  }

  .first-block {
    margin: 0em 1.5em;
  }

  .second-block {
    margin: 0em 1.5em;
  }

  @font-face {
    font-family: BlissLight;
    src: url(../assets/fonts/BlissLight.ttf);
  }

  @font-face {
    font-family: BlissMedium;
    src: url('../assets/fonts/BlissProMedium.otf');
  }

  @font-face {
    font-family: FontAwesome;
    src: url('../assets/fonts/Font Awesome 5 Free-Solid-900.otf');
  }

  h1 {
    color: #53565A;
    font-family: BlissLight;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 0.1rem;
    text-align: center;
  }

  h3 {
    color: #909090;
    font-family: BlissLight;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.1rem;
    text-align: center;
  }

  @media screen and (max-width: 306px) {
    h3 {
      font-size: 16px;
    }
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 2em 15em 3em 15em;
    padding: 0;
  }

  @media screen and (max-width: 600px) {
    hr {
      margin: 2em 2em 2em 2em;
    }
  }

  .tagline {
    max-width: 100%;
    max-height: 95px;
    margin: 0em 0em 3em 0em;
    background-color: #E9EDEF;
  }

  .tagline p {
    padding: 2em 2em;
    font-family: BlissLight;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.1rem;
    color: #909090;
    text-align: center;
    text-transform: uppercase;
  }

  .tagline + div {
    max-width: 100%;
    margin: 0em 0em 3em 0em;
    background-color: #e9edef;
  }

  .we_offer {
    position: relative;
  }

  .we_offer > img:first-child {
    width: 35%;
    margin-top: 0px;
    left: 0;
    position: absolute;
    z-index: 1;
  }

  .we_offer > img:last-child {
    max-width: 100%;
  }

  @media screen and (min-width: 450px) {
    .we_offer > img:first-child {
      max-width: 15%;
    }

    .we_offer > img:last-child {
      max-width: 30% !important;
    }
  }

  .few_steps {
    max-width: 100%;
    height: 205px;
    margin: 0em 0em 2em 0em;
    background-color: #007398;
  }

  .few_steps > p:first-child {
    padding: 2em 2em;
    font-family: BlissLight;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.1rem;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
  }

  .few_steps_img {
    position: relative;
    margin-top: -20px;
    transform: translate(300%, -180%);
  }

  .few_steps_second-img {
    max-width: 60px !important;
  }

  @media screen and (max-width: 600px) {
    .few_steps_img {
      transform: translate(290%, -190%);
    }

    .few_steps > img {
      width: 10% !important;
    }
  }

  @media screen and (max-width: 360px) {
    .few_steps {
      height: 260px;
    }

    .few_steps > p:first-child {
      font-size: 20px;
    }
    .few_steps_img {
      display: block;
      transform: translate(240%, -220%);
    }

    .few_steps > img {
      width: 12% !important;
    }
  }

  @media screen and (max-width: 280px) {
    .few_steps_img {
      display: block;
      transform: translate(180%, -250%);
    }
  }

  .few_steps > img {
    width: 4%;
    margin: -8em auto 1em auto;
  }

  .few_steps > p:last-child {
    font-family: BlissLight;
    font-weight: 200;
    font-size: 18px;
    letter-spacing: 0.1rem;
    color: #ffffff;
    text-align: center;
  }

  .service-wrapper {
    padding: 0 20px;
  }

  .select_service {
    width: 100%;
    max-width: 600px;
    height: auto;
    min-height: 440px;
    margin: 0em auto 2em;
    padding-bottom: 25px;
    border: 2px solid #cccccc;
    border-bottom: 4px solid #cccccc;
    background-color: #FAFBFC;
    border-radius: 50px 0 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .select {
    max-width: 100%;
    height: 50px;
    margin: 2em 1.4em 1em 1.4em;
    padding: 0.8em 0;
    border: 2px solid #00a9e0;
    border-radius: 10px 0 10px 0;
    background-color: #00a9e0;
    font-family: BlissMedium;
    font-weight: 300;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
  }

  .service-btn {
    max-width: 100%;
    height: 40px;
    border: solid #cccccc;
    border-width: 1px 2px 3px 2px;
    border-radius: 4px 4px;
    background-color: #e9edef;
    font-family: BlissLight;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.1rem;
    color: #53565A;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }

  .service-content {
    // display: none;
    margin-bottom: 0px;
    margin-left: 20px;
    background-color: #FAFBFC;
  }

  .service-content a {
    height: auto;
    padding: 5px 0px 5px 2em;
    margin: 0em 1.6em 0em 1.6em;
    border: solid #cccccc;
    border-width: 1px 2px 3px 2px;
    border-radius: 1.5px;
    background-color: #FAFBFC;
    color: black;
    font-family: BlissLight;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.05rem;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .service-content:last-child {
    margin-bottom: 20px;
  }

  .service:hover .service-content {
    display: block;
    // animation: fadeIn 1.5s;
    transition: all 1.5s ease-in-out;
    // -webkit-animation: fadeIn 1.5s 1 ease-in-out;
  }

  .service:hover .service-btn {
    color: #000000;
  }

  @-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
</style>
