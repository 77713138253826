<template>
  <div class="home">
    <Header :isBook='!isBook' :isArrival='!getStorageInfo.storageDateEnd ? true : false'/>
    <div class="info-wrapper">
      <div class="payment">
        <div class="payment--content">
          <div class="payment-header">
            <p>Payment</p>
          </div><br>
          <p>Please accept below our terms and conditions and privacy policy</p>
          <br>
          <Links link-location="column" />
          <br><br><br>
          <div class="terms-block">
            <input type="checkbox" id="terms-block" v-model="accepted" @click="accepted = true">
            <label for="terms-block">I accept the terms and conditions & the Privacy Policy of CPH Luggage Service</label>
          </div><br>
          <p>Continue to payment by clicking "Payment" below.
            After payment you will receive an email with your order details
          </p>
          <br>
          <button
            class="payment-btn"
            :disabled="!accepted || loading"
            @click="makePayment"
          >
            <div class="payment-btn__content">
              <div v-if="loading" class="loader-el" />
              <span v-else>Payment</span>
            </div>
          </button>
          <br>
          <div class="payment-block">
            <div class="buttons-container" style="float: left">
              <button class="btn-back payment-back" @click="$route.query.orderOnDock ? goToService('OrderDetails', service) : goTo('OrderDetails', service)">Back</button>
            </div>
          </div><br>
        </div>
      </div>
      <BasketInfo :isArrival='!getStorageInfo.storageDateEnd ? true : false' class="basket-info-hidden" />
    </div>

    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
      @loading="v => loading = v"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import additionalFunctions from '../mixins/additionalFunctions'
import Header from '@/components/Header'
import BasketInfo from '@/components/BasketInfo'
import Links from '@/components/TCAndPrivacyPolicyLinks'

export default {
  name: 'TermsAndConditions',

  props: ['service'],

  components: {
    Header,
    BasketInfo,
    Links,
    StripeCheckout
  },

  mixins: [additionalFunctions],

  data () {
    return {
      isBook: true,
      accepted: false,

      loading: false,
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      sessionId: null
    }
  },

  computed: {
    ...mapGetters([
      'getOrderInfo', 'getStorageInfo', 'priceCalculation',
      'getCustomerInfo', 'getState']),
    getHost () {
      return window.location.hostname === 'localhost'
        ? `http://${window.location.host}`
        : `https://${window.location.host}`
    },
    successURL () {
      return `${this.getHost}/after-payment?session_id={CHECKOUT_SESSION_ID}`
    },
    cancelURL () {
      return `${this.getHost}/terms-and-conditions`
    },
    paymentName () {
      return this.getOrderInfo.from === 'storage'
        ? 'Storage'
        : `${this.getOrderInfo.from} - ${this.getOrderInfo.to}`
    }
  },

  methods: {
    ...mapMutations(['goToMainPage', 'setPaymentIntent', 'setState', 'addFromTo']),
    makePayment () {
      localStorage.setItem('state', JSON.stringify(this.getState))
      this.$refs.checkoutRef.redirectToCheckout()
    },
    goTo (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    }
  },

  async created () {
    try {
      this.loading = true

      const response = await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
        ? process.env.VUE_APP_API_SERVER
        : 'http://localhost:3100'}/stripe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.paymentName,
          customer_email: this.getCustomerInfo.customerEmail,
          amount: (!this.getStorageInfo.numberOfBags && !this.getStorageInfo.numberOfHeavyBags)
            ? this.priceCalculation(true)
            : this.priceCalculation(false),
          cancel_url: this.cancelURL,
          success_url: this.successURL
        })
      })

      const data = await response.json()
      this.sessionId = data.id
      this.setPaymentIntent(data.payment_intent)
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },

  mounted () {
    this.goToMainPage()
  }
}
</script>

<style scoped lang="scss">
  .loader-el {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #00a9e0; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  input[type="checkbox"] {
    -webkit-appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    -ms-appearance: checkbox !important;
    -o-appearance: checkbox !important;
    appearance: checkbox !important;
  }

  .info-wrapper {
    margin-bottom: 0;
    min-height: 100vh;
  }

  .payment {
    width: 100%;
    height: auto;
    margin: 110px 0 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
  }

  .payment--content {
    max-width: 500px;

  }

  .payment-header {
    background: #3da1de;
    border: 1px solid #3da1de;
    border-radius: 5px;
  }

  .payment p {
    color: #53565A;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.1rem;
 }

  .payment-header p {
    height: 15px;
    margin: 20px;
    color: #ffffff;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.1rem;
  }

  ul {
    margin: auto 1em;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    color: #3da1de !important;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 0.1rem;
    text-decoration: none;
  }

  .terms-block > span {
    color: #ffffff;
    font-family: BlissLight;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.1rem;
  }

  .terms-block {
    padding: 30px 2em 30px 2em;
    background: #3da1de;
    border: 1px solid #3da1de;
    border-radius: 5px;
  }

  .terms-block > input {
    width: 40px;
    margin: 3px 0px 0px -10px;
    box-shadow: none;
    -ms-transform: scale(1); /* IE */
    -moz-transform: scale(1); /* FF */
    -webkit-transform: scale(1); /* Safari and Chrome */
    -o-transform: scale(1); /* Opera */
    transform: scale(1);
  }

  .terms-block > label {
    width: 100%;
    color: #ffffff;
    font-family: BlissLight;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }

  .payment-block {
    width: 100%;
    display: block;
    margin: auto;
  }

  .payment-btn {
    display: block;
    height: 45px;
    width: 100%;
    border: 2px solid #cccccc;
    border-radius: 5px;
    margin: 20px auto;
    background: #f8a203;
    color: #000000;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0.1rem;
    cursor: pointer;

    &__content {
      display: flex;
      justify-content: center;
    }
  }

  .payment-btn:disabled {
    background: #c4c4c4;
    cursor: not-allowed;
  }

  .payment-back {
    background: #53565A;
  }
</style>
