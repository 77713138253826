import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'
import Service from '../views/Service'
import ArrivalInfo from '../views/ArrivalInfo'
import StorageInfo from '../views/StorageInfo'
import DeliveryInfo from '../views/DeliveryInfo'
import TravellerInfo from '../views/TravellerInfo'
import OrderDetails from '../views/OrderDetails'
import TermsAndConditions from '../views/TermsAndConditions'
import PaymentForm from '../views/PaymentForm'
import AfterPayment from '../views/AfterPayment'
import CustomerOrder from '../views/CustomerOrder'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    props: true
  },
  {
    path: '/arrival-info',
    name: 'ArrivalInfo',
    component: ArrivalInfo,
    props: true
  },
  {
    path: '/storage-info',
    name: 'StorageInfo',
    component: StorageInfo,
    props: true
  },
  {
    path: '/delivery-info',
    name: 'DeliveryInfo',
    component: DeliveryInfo,
    props: true
  },
  {
    path: '/traveller-info',
    name: 'TravellerInfo',
    component: TravellerInfo,
    props: true
  },
  {
    path: '/order-details',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    props: true
  },
  {
    path: '/payment-form',
    name: 'PaymentForm',
    component: PaymentForm,
    props: true
  },
  {
    path: '/after-payment',
    name: 'AfterPayment',
    component: AfterPayment,
    props: true
  },
  {
    path: '/customer-order',
    name: 'CustomerOrder',
    component: CustomerOrder,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
