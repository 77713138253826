<template>
  <div v-if="opened" class="customer-order-dropdowns__content">

    <div v-if="orderInfo.from === 'Airport'">
      <h3>FOR A SMOOTH ARRIVAL...</h3>
      <div class="customer-order-dropdowns__content--label">Before leaving for the airport</div>
      <div class="customer-order-dropdowns__content--take-photos">
        <div>
          <img src="@/assets/images/customer_order/phone-in-hand.png" alt="phone in hand">
          <p>Take a photo of your bags and upload it here (optional)</p>
          <button @click="showTakePhotos">
            Take photos
            <img src="@/assets/images/customer_order/camera-icon.svg" alt="camera-icon">
          </button>
        </div>
      </div>
      <div class="customer-order-dropdowns__content--label">In departing airport</div>
      <div class="customer-order-dropdowns__content--take-photos">
        <div>
          <img src="@/assets/images/customer_order/barcode-example.png" alt="barcode example">
          Please scan airline bag-receipt here
          <br>
          <button @click="showScanBarcode"><span>Scan Barcode</span></button>
        </div>
      </div>
    </div>

    <div v-if="orderInfo.from === 'Cruise'" class="luggage-info-and-photos--hotel-to-cruise">
      <h3>IMPORTANT FOR A SMOOTH LUGGAGE TRANSPORT...</h3>
      <div class="luggage-info-and-photos--hotel-to-cruise-description">
        As your Copenhagen arrival approaches, please go through these steps to ensure identification and a smooth transfer of your luggage.
        You can already now upload a picture of your bags – this is optional.
      </div>

      <div class="luggage-info-and-photos-dropdowns">
        <div class="luggage-info-and-photos-dropdowns--dropdown luggage-info-and-photos-dropdowns--dropdown-exclamation" @click="examinationDisembarkTagColor">
          <span>1. Color of ship disembarkation tag</span>
          <img v-if="!disembarkTagColor" class="exclamation--small" src="@/assets/images/exclamation.png" alt="">
        </div>
        <div v-if="disembarkTagColorOpened" class="luggage-info-and-photos-dropdowns--dropdown-content">
          <span>A few days before disembarkation, the cruise ship will provide you with a colored disembarkation tag. Please state the tag-color here:</span>
          <input
            type="text"
            placeholder="e.g., GREEN 15"
            v-model="disembarkTagColor"
            :class="{'color-input-unvalied' : colorInputIsUnvalied}"
          >
          <button @click="sendData('disembarkTagColor', disembarkTagColor)">CONFIRM COLOR</button>
          <!-- <div class="divider"></div>
          <p class="hint-icon" @click="showImageOfDisembarkHint">Images of disembark tags</p>
          <div class="luggage-info-and-photos-dropdowns--dropdown-content-images">
            <img src="@/assets/images/customer_order/ship-disembark-tag-image-blue.png" alt="ship disembark tag example 1">
            <img src="@/assets/images/customer_order/ship-disembark-tag-image-green.png" alt="ship disembark tag example 2">
          </div> -->
        </div>

        <div class="luggage-info-and-photos-dropdowns--dropdown" @click="attachPhotoOpened = !attachPhotoOpened">
          <span>2. Attach a photo (optional)</span>
        </div>
        <div
          v-if="attachPhotoOpened"
          class="luggage-info-and-photos-dropdowns--dropdown-content-take-photos"
        >
          <div>
            <img src="@/assets/images/customer_order/phone-in-hand.png" alt="phone in hand">
            <p>You may attach a picture of your bags, either one photo per bag or all in the same picture.</p>
            <br>
            <p>It is not mandatory to attach a picture. In rare cases the tags may be ripped off during ship handling and then the pictures can be used to identify your bag. Otherwise, we may call you in such a situation.</p>
            <button v-if="photoNotUploaded" @click="showTakeShipTagPhotos">
              Take photos
              <img src="@/assets/images/customer_order/camera-icon.svg" alt="camera icon">
            </button>
          </div>
          <div class="luggage-info-and-photos-upload">
            <div
              v-for="(image, index) in arrayImagesOfBags"
              :key="image.id"
              class="luggage-info-and-photos-upload--box"
            >
              <div class="luggage-info-and-photos-upload--box--add-photos">
                <p class="title-container-bag">BAG {{index + 1}} </p>
                <!-- <img class="camera-icon camera-icon--resizing" src="@/assets/images/customer_order/camera-icon.svg" alt="camera icon">
                <p class="title-container">Add <br> Photos</p> -->
                <!-- <input type="file" :ref="`file${index}`" @change="addImageSlider(`file${index}`, index)" style="display: none" :alt="`${index}`"> -->
              </div>
              <div class="slider-box">
                <img
                  :aria-disabled="leftArrowSlider"
                  @click="ScrollBack(index)"
                  :class="{'slider-box--left-arrow':true}"
                  src="@/assets/images/customer_order/slider-arrow.svg"
                  alt="left-arrow"
                  :ref="`left-arrow-${index}`"
                >
                <img @click="showBigPhotos(index)" :src="arrayImagesOfBags[index].images[arrayImagesOfBags[index].numberOfPhoto]" class="slider-box--image">
                <img
                  :disabled="rightArrowSlider"
                  @click="ScrollForward(index)"
                  :class="{'slider-box--right-arrow':true}"
                  src="@/assets/images/customer_order/slider-arrow.svg"
                  alt="right-arrow"
                  :ref="`right-arrow-${index}`"
                >
                <img @click="deleteCurrentImage(index)" class="icon-trash" src="@/assets/images/customer_order/trash-can.png" alt="">
              </div>
            </div>
            <div v-if="showAddPhotos" @click="openExplorer()" class="customer-order-dropdowns__content-container--photo-bag resizing--photo-bag">
                <img class="camera-icon" src="@/assets/images/customer_order/camera-icon.svg" alt="camera icon">
                <p class="title-container">Photo(s) of bag(s)</p>
                <input type="file" ref="newBag" @change="handleFileUpload()" style="display: none">
            </div>
          </div>
        </div>

        <div class="luggage-info-and-photos-dropdowns--dropdown" @click="attachTagOpened = !attachTagOpened">
          <span>3. Attach a self-print tag</span>
        </div>
        <div v-if="attachTagOpened" class="luggage-info-and-photos-dropdowns--dropdown-content">
          <div v-if="!orderInfo.noprintself">
            <p>Attach the self-print tag you received at booking time. Via the link below you can see a video of how to use the tag: <a class="printselftag-link" target="_blank" href="https://www.copenhagenluggageservice.com/printselftag/">See here</a></p>
          <!-- <div class="luggage-info-and-photos-dropdowns--dropdown-content-self-printed-tag">
            <img
              src="@/assets/images/customer_order/self-printed-tag-example.png"
              alt="self printed tag example"
            >
          </div> -->
          <div class="divider"></div>
          <div class="luggage-info-and-photos-dropdowns--dropdown-content-send-email-with-tag">
            <p>Get an e-mail sent with the self-print tags.</p>
            <button @click="getPdf()">
              <!-- <p>SEND E-MAIL WITH</p>
              <p>Print-self tags</p> -->
              <p>Send me the self-print tags again</p>
            </button>
            <!-- <p class="hint-icon" @click="showHowToHoldHint">HOW TO FOLD?</p> -->
          </div>
          </div>
          <div v-else>
            <p>You have chosen not to use self-print tags and you have received an alternative guideline for how to mark your bags</p>
          </div>
        </div>

        <div class="luggage-info-and-photos-dropdowns--dropdown luggage-info-and-photos-dropdowns--dropdown-exclamation" @click="haveNotTagOpened = !haveNotTagOpened">
          <span>4. You don ́t have a self-print tag</span>
          <img class="exclamation--small" src="@/assets/images/exclamation.png" alt="">
        </div>
        <div v-if="haveNotTagOpened" class="luggage-info-and-photos-dropdowns--dropdown-content">
          <div v-if="orderInfo.noprintself">
            <p class="text-center">THREE THINGS YOU MUST DO<br>if you do not have a self-print tag</p>
            <br>
            <p>Clearly write the following on the ship disembarkation tag:</p><br>
            <p class="luggage-info-and-photos-dropdowns--dropdown-content--item-list">
              1. "CPH-LUG" on the tag
            </p>
            <p class="luggage-info-and-photos-dropdowns--dropdown-content--item-list">
              2. Stateroom number
            </p>
            <p class="luggage-info-and-photos-dropdowns--dropdown-content--item-list">
              3. Use same name (the name used for the order) on all tags
            </p><br>
            <div class="luggage-info-and-photos-dropdowns--dropdown-content-ship-tag-image">
              <img src="@/assets/images/customer_order/default-disembark.png" alt="ship disembark tag example">
            </div>
          </div>
          <div v-else>
            <p class="text-center">THREE THINGS YOU MUST DO<br>if you do not have a self-print tag</p>
            <div class="divider"></div>
            <p>Please notify us via the link below that you are not able to use the self-print tag options. We will send you instructions on how to mark your bags:</p>
            <button @click="showAskPopup" class="luggage-info-and-photos-dropdowns--dropdown-content--button">CONTACT US</button>
          </div>
        </div>
      </div>

    </div>

    <transition name="co-slide">
      <div class="customer-order-modal customer-order-modal-width" v-if="showModal">
        <div class="customer-order-close-btn" @click="closeModal">
          <span>X</span>
          <span>CLOSE</span>
        </div>

        <div v-if="showBigPhoto" class="customer-order-modal--view-photo">
          <div class="customer-order-modal--box-img">
            <img class="customer-order-modal--big-photo" :src="srcBigPhoto" alt="">
          </div>
          <div class="customer-order-modal--box-buttons">
            <button @click="closeModal" class="customer-order-modal--box-buttons--button">OK</button>
            <button @click="deleteCurrentPhoto()" class="customer-order-modal--box-buttons--button">DEL</button>
          </div>
        </div>

        <div v-if="showTakePhotosContent" class="customer-order-modal--take-photos">
          <div class="customer-order-dropdowns__content--label">
            If you wish to take photos of your luggage for bag pick-up
          </div>
          <div class="customer-order-modal--luggage-photos-input">
            <input
              @change="handleFileSave"
              multiple
              name="luggage-photos-input"
              id="luggage-photos-input"
              type="file"
            >
            <label for="luggage-photos-input">
              <img src="@/assets/images/customer_order/camera-icon.svg" alt="camera icon">
              <h3>Add photos here</h3>
            </label>
          </div>
          <div v-if="luggagePhotosPreview.length" class="customer-order-modal--luggage-photos-previews">
            <div
              v-for="(image, index) in luggagePhotosPreview"
              :key="index"
              class="customer-order-modal--luggage-photos-previews-preview"
            >
              <img :src="image" :alt="`${index + 1} image preview`">
              <img
                src="@/assets/images/customer_order/trash-can.png"
                alt="trash can"
                @click="removePhoto(index)"
              >
            </div>
          </div>
          <button class="customer-order-modal--upload-button">UPLOAD PHOTOS</button>
        </div>

        <div v-if="showScanBarcodeContent">
          <div class="customer-order-dropdowns__content--label text-center">
            <p>PLEASE SCAN</p>
            <p>Airline bag tag receipt</p>
          </div>
          <div class="customer-order-modal--image-wrapper">
            <img src="@/assets/images/customer_order/barcode-example.png" alt="barcode example">
          </div>
          <div class="divider"></div>

          <div v-if="barcode && !scanBarcodeSwitcher" class="customer-order-modal--barcode-scan-result">
            <p>Last result: "{{ barcode }}"</p>
            <div>
              <button @click="sendData('barcode', barcode)">Send barcode</button>
            </div>
          </div>
          <div id="qr-code-full-region"></div>
          <div v-if="!scanBarcodeSwitcher" class="customer-order-modal--barcode-input">
            <div class="customer-order-modal--barcode-input-swither">
              <input
                type="checkbox"
                id="switch"
                v-model="scanBarcodeSwitcher"
              />
              <label for="switch">Toggle</label>
            </div>
            <div class="customer-order-modal--barcode-input-scan" @click="scanBarcode">
              <h3>Scan here</h3>
              <img src="@/assets/images/customer_order/barcode-example-2.png" alt="barcode example">
            </div>
            <div class="customer-order-modal--barcode-input-light">
              <img src="@/assets/images/customer_order/light.png" alt="light">
              <p>Light</p>
            </div>
          </div>

          <div v-if="scanBarcodeSwitcher" class="customer-order-modal--barcode-input-manual-entry">
            <div class="customer-order-modal--barcode-input-manual-entry-swither">
              <input
                type="checkbox"
                id="switch"
                v-model="scanBarcodeSwitcher"
              />
              <label for="switch">Toggle</label>
            </div>
            <div class="customer-order-modal--barcode-input-manual-entry-field">
              <label for="barcode">Enter Barcode</label>
              <input type="text" id="barcode" v-model="barcode">
              <button @click="sendData('barcode', barcode)">Enter</button>
            </div>
            <img src="@/assets/images/customer_order/light.png" alt="light">
          </div>
        </div>

        <div v-if="showTakeShipTagPhotosContent">
          <div class="customer-order-dropdowns__content--label">
            If you wish to take photos of your luggage for bag pick-up
          </div>
          <add-photos-of-bag
            :customerInfo='customerInfo'
            :orderInfo='orderInfo'
            :countContainers='countContainers'
            @arrayUrlPhotosGet='arrayUrlPhotosGet'
           />
        </div>
      </div>
    </transition>

    <transition name="co-slide">
      <div class="customer-order-modal" v-if="showHintModal">
        <div class="customer-order-close-btn" @click="closeHintModal">
          <span>X</span>
          <span>CLOSE</span>
        </div>

        <div v-if="showImageOfDisembarkHintContent"></div>

        <div v-if="showHowToHoldHintContent">
          <h3>HOW TO FOLD?</h3>
        </div>
      </div>
    </transition>

    <transition name="co-slide">
      <div class="customer-order-modal customer-order-modal-width ask-popup" v-if="isShowAskPopup">
        <div class="ask-popup-title">Confirm request for alternative to self-print:</div>
        <div class="ask-popup-text">
          <p>Are you sure you cannot use the self-print tags? If YES, we will send you information with alternatives of how to mark your bags.</p>
        </div>
        <div class="ask-popup-buttons">
          <div class="ask-popup-buttons--button" @click="closeHintModal">NO</div>
          <div class="ask-popup-buttons--button" @click="acceptAskPopup">YES</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Html5QrcodeScanner } from 'html5-qrcode'
import AddPhotosOfBag from './AddPhotosOfBag.vue'

export default {
  components: {
    AddPhotosOfBag
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    customerInfo: {
      type: Object,
      require: true
    },
    orderInfo: {
      type: Object,
      require: true,
      default () {
        return {}
      }
    }
  },
  computed: {
    countContainers () {
      return this.orderInfo.numberOfBags + this.orderInfo.numberOfHeavyBags
    }
  },
  data: () => ({
    file: '',
    fileSlider: '',
    srcBigPhoto: '',
    leftArrowSlider: false,
    rightArrowSlider: false,
    photoNotUploaded: true,
    showAddPhotos: false,
    arrayImagesOfBags: [],
    visibleLeftArrow: false,
    visibleRightArrow: false,
    IndexPhotoForDelete: '',
    colors: [
      'Red',
      'Grey',
      'Blue',
      'Light blue',
      'Dark blue',
      'Yellow',
      'Pink',
      'Orange',
      'Brown',
      'White',
      'The black',
      'Purple',
      'Gold',
      'Silver',
      'Amber',
      'Azure',
      'Beige',
      'Bodily',
      'Bronze',
      'Chocolate',
      'Coral',
      'Lime'
    ],
    colorInputIsUnvalied: false,
    isExclamation: false,
    isShowAskPopup: false,
    showModal: false,
    showBigPhoto: false,
    showBigPhotoContent: false,
    showTakePhotosContent: false,
    showScanBarcodeContent: false,
    luggagePhotos: [],
    luggagePhotosPreview: [],
    disembarkTagColorOpened: false,
    attachPhotoOpened: false,
    attachTagOpened: false,
    haveNotTagOpened: false,
    scanBarcodeSwitcher: false,
    barcode: '',
    showTakeShipTagPhotosContent: false,
    showHintModal: false,
    showImageOfDisembarkHintContent: false,
    showHowToHoldHintContent: false,
    scannerActive: false,
    disembarkTagColor: '',
    currentOrder: {}
  }),
  methods: {
    examinationDisembarkTagColor () {
      this.disembarkTagColorOpened = !this.disembarkTagColorOpened
      // if (this.orderInfo.disembarkTagColor) {
      //   this.disembarkTagColor = this.orderInfo.disembarkTagColor
      // }
    },
    async acceptAskPopup () {
      alert('You will soon receive an email with info about how to mark your bags without self-print-tags')
      this.closeHintModal()
      this.sendData('noprintself', true)
      const totalObj = {
        customerInfo: this.customerInfo,
        orderInfo: this.orderInfo
      }
      try {
        await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER // https://luggage-service-api.herokuapp.com - for production
          : 'http://localhost:3100'}/info-print`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(totalObj)
        })
      } catch (err) {
        console.log('An error has occured ' + err.name)
      }
    },
    showAskPopup () {
      this.isShowAskPopup = true
    },
    async getPdf () {
      const totalObj = {
        customerInfo: this.customerInfo,
        orderInfo: this.orderInfo,
        color: ''
      }
      try {
        await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER // https://luggage-service-api.herokuapp.com - for production
          : 'http://localhost:3100'}/get-pdf`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(totalObj)
        })
        alert('Self-print luggage tag is now sent to your email')
      } catch (err) {
        console.log('An error has occured ' + err.name)
      }
    },
    removeVisibilityArrow (index, direction) {
      const Arrow = this.$refs[`${direction}-arrow-${index}`]
      Arrow[0].classList.remove(`slider-box--${direction}-arrow--visible`)
    },
    addVisibilityArrow (index, direction) {
      const Arrow = this.$refs[`${direction}-arrow-${index}`]
      Arrow[0].classList.add(`slider-box--${direction}-arrow--visible`)
    },
    ScrollBack (index) {
      if ((this.arrayImagesOfBags[index].numberOfPhoto - 1) < 0) {
        this.arrayImagesOfBags[index].numberOfPhoto = 0
        this.leftArrowSlider = false
        this.removeVisibilityArrow(index, 'left')
      } else {
        this.arrayImagesOfBags[index].numberOfPhoto = this.arrayImagesOfBags[index].numberOfPhoto - 1
        this.leftArrowSlider = true
        this.rightArrowSlider = true
        this.addVisibilityArrow(index, 'right')
        this.addVisibilityArrow(index, 'left')
      }
      if (this.arrayImagesOfBags[index].numberOfPhoto === 0) {
        this.leftArrowSlider = false
        this.rightArrowSlider = true
        this.addVisibilityArrow(index, 'right')
        this.removeVisibilityArrow(index, 'left')
      }
    },
    ScrollForward (index) {
      this.leftArrowSlider = true
      this.addVisibilityArrow(index, 'left')
      if (this.arrayImagesOfBags[index].numberOfPhoto === (this.arrayImagesOfBags[index].images.length - 1)) {
        this.rightArrowSlider = false
        this.removeVisibilityArrow(index, 'right')
        this.arrayImagesOfBags[index].numberOfPhoto = this.arrayImagesOfBags[index].images.length - 1
      } else {
        this.arrayImagesOfBags[index].numberOfPhoto = this.arrayImagesOfBags[index].numberOfPhoto + 1
        if (this.arrayImagesOfBags[index].numberOfPhoto === (this.arrayImagesOfBags[index].images.length - 1)) {
          this.rightArrowSlider = false
          this.removeVisibilityArrow(index, 'right')
        }
      }
    },
    async handleFileUpload () {
      this.file = this.$refs.newBag.files[0]
      this.$refs.newBag.value = '' // this string for clearing fake path of image
      // this.arrayImagesOfBags.push({ images: [URL.createObjectURL(this.file)], numberOfPhoto: 0 })

      const file123 = this.file
      const reader = new FileReader()
      reader.readAsDataURL(file123)
      reader.onloadend = () => {
        this.uploadImg(reader.result)
      }
      // const newImg = await this.fileListToBase64(file123)
      // const reader = new FileReader()
      // let rawImg
      // const newImg = []
      // reader.onloadend = await function () {
      //   debugger // eslint-disable-line no-debugger
      //   rawImg = reader.result
      //   console.log(rawImg)
      //   debugger // eslint-disable-line no-debugger
      //   newImg.push(rawImg)
      // }
      // await reader.readAsDataURL(file123)
      // console.log(newImg)
      // debugger // eslint-disable-line no-debugger
      // await this.uploadImg(newImg)
      this.arrayImagesOfBags.length < this.countContainers ? this.showAddPhotos = true : this.showAddPhotos = false
    },
    // async fileListToBase64 (file123) {
    //   const reader = await new FileReader()
    //   let rawImg
    //   const newImg = []
    //   reader.onloadend = () => {
    //     rawImg = reader.result
    //     console.log(rawImg)
    //     newImg.push(rawImg)
    //     return newImg
    //   }
    //   await reader.readAsDataURL(file123)
    //   return newImg
    // },
    async uploadImg (newImg) {
      const today = new Date()
      const totalObj = {
        customerId: this.customerInfo._id,
        orderNumber: this.orderInfo.orderNumber,
        images: [newImg],
        addImg: true,
        orderInfo: {
          orderChangedDate: `${Date.parse(today)}`
        }
        // urlsImages: this.urlsImages
      }
      // console.log(totalObj)
      // debugger // eslint-disable-line no-debugger

      try {
        await fetch(
      `${process.env.VUE_APP_PROD_MODE === 'true'
        ? process.env.VUE_APP_API_SERVER
        : 'http://localhost:3100'}/up-img`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(totalObj)
        // body: totalObj
      })
      } catch {
        alert('Something went wrong!')
      }
      this.arrayUrlPhotosGet()
    },
    openExplorer () {
      this.$refs.newBag.click()
    },
    openExlorerSlider (ref) {
      this.$refs[ref][0].click()
    },
    addImageSlider (ref, index) {
      this.fileSlider = this.$refs[ref][0].files[0]
      this.$refs[ref][0].value = ''
      this.arrayImagesOfBags[index].images.push(URL.createObjectURL(this.fileSlider))
      if (this.arrayImagesOfBags[index].images.length > 1) {
        this.rightArrowSlider = true
        this.visibleRightArrow = true
      }
      this.addVisibilityArrow(index, 'right')
    },
    showTakePhotos () {
      this.showModal = true
      this.showTakePhotosContent = true
    },
    showBigPhotos (index) {
      this.showBigPhoto = true
      this.showBigPhotoContent = true
      this.showModal = true
      this.IndexPhotoForDelete = index
      this.srcBigPhoto = this.arrayImagesOfBags[index].images[this.arrayImagesOfBags[index].numberOfPhoto]
    },
    deleteCurrentPhoto () {
      this.deleteCurrentImage(this.IndexPhotoForDelete)
      this.closeModal()
    },
    showScanBarcode () {
      this.showModal = true
      this.showScanBarcodeContent = true
    },
    showTakeShipTagPhotos () {
      this.showModal = true
      this.showTakeShipTagPhotosContent = true
    },
    closeModal () {
      this.showModal = false
      this.showTakePhotosContent = false
      this.showScanBarcodeContent = false
      this.showTakeShipTagPhotosContent = false
      this.showBigPhoto = false
      this.barcode = undefined
    },
    arrayUrlPhotosGet () {
      // this.$forceUpdate()
      this.$emit('arrayUrlPhotosGet')
    },
    arrayUrlPhotos (source) {
      this.arrayImagesOfBags = source.map(current => ({ images: [current], numberOfPhoto: 0 }))
      this.closeModal()
      this.photoNotUploaded = !this.arrayImagesOfBags.length
      this.arrayImagesOfBags.length < this.countContainers ? this.showAddPhotos = true : this.showAddPhotos = false
      if (this.arrayImagesOfBags.length === 0) {
        this.photoNotUploaded = true
        this.showAddPhotos = false
      }
    },
    async deleteCurrentImage (index) {
      if (this.arrayImagesOfBags[index].images.length === 1) { // delete last photo of bag and section
        const new1 = this.arrayImagesOfBags[index].images[0].lastIndexOf('/') + 1
        const new2 = this.arrayImagesOfBags[index].images[0].lastIndexOf('.')
        const new3 = this.arrayImagesOfBags[index].images[0].slice(new1, new2)
        const today = new Date()
        const totalObj = {
          customerId: this.customerInfo._id,
          orderNumber: this.orderInfo.orderNumber,
          link: this.arrayImagesOfBags[index].images[0],
          orderInfo: {
            orderChangedDate: `${Date.parse(today)}`
          },
          id: new3
          // urlsImages: this.urlsImages
        }
        try {
          await fetch(
          `${process.env.VUE_APP_PROD_MODE === 'true'
            ? process.env.VUE_APP_API_SERVER
            : 'http://localhost:3100'}/rem-img`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(totalObj)
          })
        } catch {
          alert('Something went wrong!')
        }
        this.arrayImagesOfBags = this.arrayImagesOfBags.filter(el => el !== this.arrayImagesOfBags[index])

        if (this.arrayImagesOfBags.length) {
          this.arrayImagesOfBags.forEach(async (el, id) => {
            // const new1 = el.images[0].lastIndexOf('/') + 1
            // const new2 = el.images[0].lastIndexOf('.')
            // const new3 = el.images[0].slice(new1, new2)
            // const totalObj = {
            //   customerId: this.customerInfo._id,
            //   orderNumber: this.orderInfo.orderNumber,
            //   link: el.images[0],
            //   id: new3
            //   // urlsImages: this.urlsImages
            // }
            // try {
            //   await fetch(
            //   `${process.env.VUE_APP_PROD_MODE === 'true'
            //     ? 'https://luggage-service-api-dev.herokuapp.com'
            //     : 'http://localhost:3100'}/rem-img`, {
            //     method: 'POST',
            //     headers: {
            //       'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(totalObj)
            //   })
            // } catch {
            //   alert('Something went wrong!')
            // }
            // debugger // eslint-disable-line no-debugger
            el.numberOfPhoto = 0
            // if (el.images.length === 1) {
            //   el.numberOfPhoto = 0
            //   this.removeVisibilityArrow(id, 'right')
            //   this.removeVisibilityArrow(id, 'left')
            // } else if (el.images.length > 1) {
            //   el.numberOfPhoto = 0
            //   this.addVisibilityArrow(id, 'right')
            //   this.removeVisibilityArrow(id, 'left')
            // }
            this.showAddPhotos = true
          })
        }
        // i think in this moment need changing next index with number of photo equals 0
        // and remove left arrow & add right arrow
        if (this.arrayImagesOfBags.length === 0) { // hide all sections if number of bags equals 0
          this.showAddPhotos = false
          this.photoNotUploaded = true
        }
      }
      // else {
      //   debugger // eslint-disable-line no-debugger
      //   this.arrayImagesOfBags[index].images
      //     .splice(this.arrayImagesOfBags[index].numberOfPhoto, 1)
      //   this.arrayImagesOfBags[index].numberOfPhoto = 0
      //   this.addVisibilityArrow(index, 'right')
      //   this.removeVisibilityArrow(index, 'left')
      //   if (this.arrayImagesOfBags[index].images.length === 1) {
      //     debugger // eslint-disable-line no-debugger
      //     this.removeVisibilityArrow(index, 'right')
      //     this.removeVisibilityArrow(index, 'left')
      //   } else {
      //     debugger // eslint-disable-line no-debugger
      //     this.arrayImagesOfBags[index].numberOfPhoto = 0
      //     this.addVisibilityArrow(index, 'right')
      //     this.removeVisibilityArrow(index, 'left')
      //   }
      // }
    },
    showImageOfDisembarkHint () {
      this.showHintModal = true
      this.showImageOfDisembarkHintContent = true
    },
    showHowToHoldHint () {
      this.showHintModal = true
      this.showHowToHoldHintContent = true
    },
    closeHintModal () {
      this.isShowAskPopup = false
      this.showHintModal = false
      this.showImageOfDisembarkHintContent = false
      this.showHowToHoldHintContent = false
    },
    handleFileSave (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        this.luggagePhotos = [...input.files]
        for (let i = 0; i < this.luggagePhotos.length; i++) {
          this.luggagePhotosPreview.push(URL.createObjectURL(this.luggagePhotos[i]))
        }
      }
    },
    removePhoto (index) {
      this.luggagePhotos.splice(index, 1)
      this.luggagePhotosPreview.splice(index, 1)
    },
    scanBarcode () {
      const config = {
        fps: 10,
        qrbox: 170
      }
      const html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', config)
      html5QrcodeScanner.render(this.onScanSuccess)
    },
    onScanSuccess (decodedText, decodedResult) {
      this.barcode = decodedText
    },
    async sendData (valName, value) {
      let totalObj = {}
      const today = new Date()
      if (valName === 'disembarkTagColor' || valName === 'noprintself') {
        if (valName === 'disembarkTagColor') {
          this.currentOrder.disembarkTagColor = value
          console.log(this.currentOrder)
          console.log(this.currentOrder.disembarkTagColor)
        }
        totalObj = {
          customerId: this.customerInfo._id,
          orderNumber: this.orderInfo.orderNumber,
          orderInfo: {
            [valName]: value,
            orderChangedDate: `${Date.parse(today)}`
          }
        }
      } else {
        totalObj = {
          customerId: this.customerInfo._id,
          orderNumber: this.orderInfo.orderNumber,
          orderInfo: {
            [valName]: value
          }
        }
      }

      try {
        await fetch(
      `${process.env.VUE_APP_PROD_MODE === 'true'
        ? process.env.VUE_APP_API_SERVER
        : 'http://localhost:3100'}/update-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(totalObj)
      }).then((response) => {
          if (response.status === 200) {
            this.orderInfo[valName] = value
            if (valName === 'noprintself') {} else alert('You have now confirmed the color of your disembarkation text, thanks!')
            if (valName === 'disembarkTagColor') {
              this.disembarkTagColor = value
            }
          }
        })
      } catch {
        alert('Something went wrong!')
      }
      this.isExclamation = false
      this.colorInputIsUnvalied = false
    }
  },
  watch: {
    'orderInfo.imgUrl': {
      handler: function (val) {
        // console.log('12312312323')
        // console.log(this.orderInfo.imgUrl)
        if (this.orderInfo.imgUrl) {
          const totalObj = [
            this.orderInfo.imgUrl
          ]
          // debugger // eslint-disable-line no-debugger
          this.arrayUrlPhotos(totalObj[0])
        }
        // console.log(this.arrayImagesOfBags)
        // debugger // eslint-disable-line no-debugger
      },
      immediate: true
    },
    orderInfo: {
      handler: function (val) {
        if (this.orderInfo.disembarkTagColor) {
          this.disembarkTagColor = this.orderInfo.disembarkTagColor
        }
        if (this.orderInfo.imgUrl) {
          // const totalObj = {
          //   numberOfPhoto: 0,
          //   imges: [
          //     this.orderInfo.imgUrl
          //   ]
          // }
          const totalObj = [
            this.orderInfo.imgUrl
          ]
          // debugger // eslint-disable-line no-debugger
          this.arrayUrlPhotos(totalObj[0])
          // debugger // eslint-disable-line no-debugger
          // this.arrayImagesOfBags[0] = totalObj
          // this.arrayImagesOfBags[1] = totalObj
        }
        // console.log(this.arrayImagesOfBags)
        // debugger // eslint-disable-line no-debugger
      },
      immediate: true
    },
    showModal (newVal, oldVal) {
      if (newVal) {
        document.querySelector('html').style.overflow = 'hidden'
      } else {
        document.querySelector('html').style.overflow = 'auto'
      }
    },
    showHintModal (newVal, oldVal) {
      if (newVal) {
        document.querySelector('html').style.overflow = 'hidden'
      } else {
        document.querySelector('html').style.overflow = 'auto'
      }
    },
    scanBarcodeSwitcher () {
      this.barcode = undefined
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/customerOrder";

.printselftag-link {
  text-decoration: none;
  color: #1624da;
  text-decoration: underline;
}

.title-container-bag{
  font-weight: 600;
  width: 4em;
  margin-bottom: 10px;
}
.color-input-unvalied {
  border: 2px solid rgba(255, 0, 0, 0.6);
  box-shadow: inset 0px 0px 4px 1px rgba(255, 0, 0, 0.6) !important
}

.resizing--photo-bag {
  width: 100%;
  img {
    width: 35px;
  }
}

.luggage-info-and-photos {
  &--hotel-to-cruise {
    &-description {
      margin: 20px 35px 20px 0;
    }
    .exclamation--small {
      width: 1.5em;
      margin: 0;
    }
  }
  &-dropdowns {
    &--dropdown {
      background-color: #FC9000;
      color: white;
      margin-bottom: 10px;
      padding: 10px;
      cursor: pointer;
      &-exclamation {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-content {
        &--item-list {
          margin-bottom: .5em;
        }
        &--button {
          margin-left: 60% !important;
          width: 40% !important;
        }
        margin: -10px 0 10px;
        padding: 10px;
        border-left: 2px solid #cccccc;
        border-right: 2px solid #cccccc;
        border-bottom: 2px solid #cccccc;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.35);
        &> * {
          margin: 10px 0;
        }
        input {
          border-radius: 0;
          box-shadow: 0 0 0 0;
          width: 100%;
        }
        button {
          margin-top: 0;
          width: 100%;
          font-family: BlissLight;
          padding: 10px;
          border: 1px solid #a1a1a1;
          border-radius: 5px;
          box-shadow: 0 2px 1px rgba(0, 0, 0, 0.35);
          background-color: #bac6ce;
          letter-spacing: 0.2rem;
          font-weight: bold;
        }
        &-images {
          display: flex;
          flex-direction: row;
          justify-content: center;
          img {
            object-fit: cover;
            max-width: 46%;
            max-height: 160px;
            margin: 2%;
          }
        }
        &-take-photos {
          display: flex;
          flex-direction: column;
          color: rgb(73, 73, 73);
          margin: -10px 0 10px;
          padding: 10px 10px 20px;
          border-left: 2px solid #cccccc;
          border-right: 2px solid #cccccc;
          border-bottom: 2px solid #cccccc;
          box-shadow: 0 2px 1px rgba(0, 0, 0, 0.35);
          .luggage-info-and-photos-upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1em;
            &--box {
              position: relative;
              .camera-icon--resizing {
                width: 35px;
              }
              .slider-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                &--left-arrow {
                  width: 2em;
                  height: 2em;
                  cursor: pointer;
                  visibility: hidden;
                  &--visible {
                    visibility: visible;
                  }
                }
                &--right-arrow {
                  transform: scale(-1, 1);
                  width: 2em;
                  height: 2em;
                  cursor: pointer;
                  visibility: hidden;
                  &--visible {
                    visibility: visible;
                  }
                }
                &--image {
                  cursor: help
                }
              }
              &--add-photos {
                background-color: white;
                border-right: 2px dashed black;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                justify-content: center;
                padding: 10px;
              }
              display: flex;
              border: 2px dashed black;
              border-radius: 5px;
              margin-bottom: 0.5em;
              width: 100%;
              .icon-trash {
                right: 0;
                bottom: 0;
                position: absolute;
                max-width: 25px;
                max-height: 25px;
              }
            }
            img {
              float: none;
              max-width: 65%;
              max-height: 10em;
              margin-left: 0;
            }
          }
          img {
            float: right;
            max-height: 100px;
            max-width: 40%;
            margin-left: -10px;
          }
          button {
            margin-top: 10px;
            border: 1px solid rgb(161, 161, 161);
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
            padding-left: 10px;
            font-size: 20px;
            color: rgb(73, 73, 73);
            background-color: white;
            cursor: pointer;
            display: flex;
            align-items: center;
            img {
              padding: 3px;
              margin-right: 20px;
              margin-left: 10px;
              max-height: 35px;
            }
            span {
              padding: 7px 7px 7px 0;
            }
          }
        }
        &-self-printed-tag {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px;
          img {
            width: 100%;
            max-width: 300px;
          }
        }
        &-send-email-with-tag {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          button {
            cursor: pointer;
            padding: 3px;
            margin: 10px 0;
          }
          p {
            margin: 7px 0;
          }
        }
        &-ship-tag-image {
          display: flex;
          justify-content: center;
          img {
            height: 100%;
            max-height: 200px;
          }
        }
      }
    }
  }
}
.ask-popup {
  display: flex; flex-direction: column;
  &-title {
    font-weight: bold;
    margin-bottom: 1em;
  }
  &-text {
    margin-bottom: 1em;
  }
  &-buttons {
    text-align: center;
    display: flex; justify-content: space-between;
    font-size: 20px;
    &--button {
      font-weight: bold;
      background-color: #bac6ce;
      border: 1px solid #a1a1a1;
      border-radius: 5px;
      box-shadow: 0 2px 1px rgb(161, 161, 161);
      cursor: pointer;
      width: 20%;
    }
  }
}
</style>
