<template>
  <div class="home">
    <Header :isBook='!isBook' :isArrival='!getStorageInfo.storageDateEnd ? true : false'/>
    <div class="info-wrapper">
      <div class="payment-form">
        <div class="payment-form--content">
          <div class="payment-form-header">
            <h3>Payment Form</h3>
            <p>Please complete your order</p>
          </div>
          <div class="payment-form-data">
            <input type="email" class="card-inputs-style" v-model="email" @keyup="validateEmail(email)"
                  placeholder="Email" required><br>
            <div class="cards-block">
              <small v-if="!validEmail">Please enter correct email</small>
            </div><br>
            <div id="card-number" class="card-inputs-style" @keyup="checkCardValues()"></div>
            <div class="cards-block">
              <small class="first-small" v-if="errors.cardNumberInvalid">{{ errors.cardNumberInvalid }}</small>
            </div>
            <div class="cards-block">
              <div id="card-expiry" class="card-elems-align" @keyup="checkCardValues()"></div>
              <div class="cards-block">
                <small class="expiry" v-if="errors.cardExpiryInvalid">{{ errors.cardExpiryInvalid }}</small>
              </div>
              <div id="card-cvc" class="card-elems-align" @keyup="checkCardValues()"></div>
              <div class="cards-block">
                <small class="expiry" v-if="errors.cardCvcInvalid">{{ errors.cardCvcInvalid }} </small>
              </div>
            </div>
            <div id="card-errors" class="card-errors" role="alert"></div>
            <!-- <div class="payment-remember-me">
            <input type="checkbox" id="remember-me" name="remember-me">
            <label for="remember-me">Remember me</label>
            </div> -->

            <!-- <button v-if="loading" class="pay-btn">
              Please, wait...
            </button> -->
            <button class="pay-btn" @click="payment()"
                    :disabled="!email || !validEmail || errors.cardNumberInvalid !== '' ||
                    errors.cardExpiryInvalid !== '' || errors.cardCvcInvalid !== '' ||
                    !errors.isComplete.cardNumber || !errors.isComplete.cardExpiry || !errors.isComplete.cardCvc || loader">
              {{ loader ? 'Please, wait...' : 'Pay' }}
            </button>
            <div class="cards-block">
              <div class="buttons-container" style="float: left">
                <button class="btn-back" @click="$route.query.orderOnDock ? goToService('TermsAndConditions', service) : $root.$refs.Home.goToService('TermsAndConditions', service)">Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasketInfo :isArrival='!getStorageInfo.storageDateEnd ? true : false' class="basket-info-hidden" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import Header from '@/components/Header'
import additionalFunctions from '../mixins/additionalFunctions'
import BasketInfo from '@/components/BasketInfo'
import { mapGetters, mapMutations } from 'vuex'
const { Stripe } = window

export default {
  name: 'PaymentForm',
  props: ['service'],
  components: {
    Header,
    BasketInfo
  },
  computed: mapGetters(
    ['getOrderInfo', 'getStorageInfo', 'getCustomerInfo', 'getStateData',
      'getAllBagsCount', 'priceCalculation', 'getStorageDays', 'getUrlPrices']),
  mixins: [additionalFunctions],
  data () {
    return {
      isBook: true,
      email: '',
      validEmail: true,
      stripe: '',
      elements: '',
      style: '',
      stripeCardNumber: '',
      stripeCardExpiry: '',
      stripeCardCvc: '',
      loader: false,
      errors: {
        isComplete: {
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false
        },
        cardNumberInvalid: '',
        cardExpiryInvalid: '',
        cardCvcInvalid: ''
      },
      payment_id: ''
    }
  },
  mounted () {
    this.goToMainPage()

    if (this.getCustomerInfo.customerEmail) {
      this.email = this.getCustomerInfo.customerEmail.trim()
    }

    this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)
    this.elements = this.stripe.elements()

    this.style = {
      base: {
        iconColor: '#666EE8',
        fontWeight: 600,
        fontFamily: '"BlissLight", sans-serif',
        fontSize: '16px',

        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }

    const stripeCardNumber = this.createStripeElement(this.stripeCardNumber, 'cardNumber', '#card-number')
    this.checkingCardAttributes(stripeCardNumber)

    const stripeCardExpiry = this.createStripeElement(this.stripeCardExpiry, 'cardExpiry', '#card-expiry')
    this.checkingCardAttributes(stripeCardExpiry)

    const stripeCardCvc = this.createStripeElement(this.stripeCardCvc, 'cardCvc', '#card-cvc')
    this.checkingCardAttributes(stripeCardCvc)

    this.checkCardValues()
  },
  methods: {
    ...mapMutations(['goToMainPage']),
    async payment () {
      try {
        this.loader = true

        const response = await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER
          : 'http://localhost:3100'}/stripe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            amount: (!this.getStorageInfo.numberOfBags && !this.getStorageInfo.numberOfHeavyBags) ? this.priceCalculation(true) : this.priceCalculation(false)
          })
        })

        const { secret } = await response.json()

        const paymentMethod = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.elements._elements[0],
          billing_details: {
            email: this.email,
            name: this.getCustomerInfo.customerName
          }
        })

        await this.stripe.confirmCardPayment(secret, {
          payment_method: paymentMethod.paymentMethod.id
        }).then((result) => {
          if (result.error) {
            alert(`Sorry, payment was unsuccessful ${result.error.message}`)
            console.log(result.error.message)

            this.loader = false
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              console.log('Payment completed successfully')
              this.payment_id = result.paymentIntent.id
              this.getInfoFromVuex()
            }
          }
        })
      } catch (error) {
        alert('Whoops, an error occured', error.message)
        console.log(error.message)

        this.loader = false
      }
    },
    createStripeElement (elem, nameOfElem, elemID) {
      let props = {}

      if (nameOfElem === 'cardNumber') {
        props = {
          showIcon: true,
          placeholder: 'Card Number',
          style: this.style
        }
      } else {
        props = {
          style: this.style
        }
      }

      elem = this.elements.create(nameOfElem, props)
      elem.mount(elemID)

      return elem
    },
    checkingCardAttributes (cardAtrribute) {
      cardAtrribute.on('change', ({ error, complete }) => {
        switch (cardAtrribute._componentName) {
          case 'cardNumber':
            if (error) {
              this.errors.cardNumberInvalid = error.message
            } else {
              this.errors.cardNumberInvalid = ''
              this.errors.isComplete.cardNumber = complete
            }

            this.errors.isEmptyCardNumber = this.isEmptyElement('#card-number')

            break
          case 'cardExpiry':
            if (error) {
              this.errors.cardExpiryInvalid = error.message
            } else {
              this.errors.cardExpiryInvalid = ''
              this.errors.isComplete.cardExpiry = complete
            }

            this.errors.isEmptyCardExpiry = this.isEmptyElement('#card-expiry')

            break
          case 'cardCvc':
            if (error) {
              this.errors.cardCvcInvalid = error.message
            } else {
              this.errors.cardCvcInvalid = ''
              this.errors.isComplete.cardCvc = complete
            }

            this.errors.isEmptyCardCvc = this.isEmptyElement('#card-cvc')

            break
        }
      })
    },
    isEmptyElement (elem) {
      const cardElem = document.querySelector(elem)
      return cardElem.classList.contains('StripeElement--empty')
    },
    checkCardValues () {
      Object.keys(this.errors.isComplete).map((cardElem) => {
        if (cardElem in this.errors.isComplete) {
          this.errors.isComplete[cardElem] = false
        }
      })
    },
    countStorageDays (dateFrom, dateEnd) {
      return Math.floor(new Date(dateEnd).getTime() - new Date(dateFrom).getTime()) / (1000 * 60 * 60 * 24)
    },
    async getInfoFromVuex () {
      let refValue = document.cookie.split(';') || []

      if (refValue.length) {
        refValue = refValue.find(c => c.includes('ref=')) || ''
        if (refValue) {
          refValue = refValue.split('=')[1]
        }
      }

      const dateNow = new Date()
      const objectMomentTimeZone = moment.tz('Europe/Copenhagen')
      const orderDate = objectMomentTimeZone.format('DD.MM.YYYY')
      const orderTime = objectMomentTimeZone.format('HH:mm:ss')
      const totalObj = {}

      totalObj.customerInfo = {
        ...this.getCustomerInfo,
        ref: refValue
      }
      totalObj.additionalInfo = this.getStateData.additionalInfo
      if (!this.getStorageInfo.numberOfBags && !this.getStorageInfo.numberOfHeavyBags) {
        totalObj.orderInfo = this.getOrderInfo
        totalObj.additionalInfo.priceInfo = this.priceCalculation(true)
        totalObj.additionalInfo.totalBags = this.getAllBagsCount(true)
        totalObj.orderInfo.arrivalDate = formatDate(this.getOrderInfo.arrivalDate)
        totalObj.orderInfo.orderDate = orderDate
        totalObj.orderInfo.orderTime = orderTime
        totalObj.orderInfo.orderChangedDate = Date.parse(dateNow)
        totalObj.orderInfo.paymentId = this.payment_id
        totalObj.orderInfo.noprintself = false
        totalObj.orderInfo.isOpen = true
      } else {
        totalObj.storageInfo = this.getStorageInfo
        totalObj.additionalInfo.priceInfo = this.priceCalculation(false)
        totalObj.additionalInfo.totalBags = this.getAllBagsCount(false)
        totalObj.storageInfo.storageDateFrom = formatDate(this.getStorageInfo.storageDateFrom)
        totalObj.storageInfo.storageDateEnd = formatDate(this.getStorageInfo.storageDateEnd)
        totalObj.additionalInfo.storageDays = this.getStorageDays
        if (console) console.log('****sto days = ' + this.getStorageDays)
        totalObj.storageInfo.orderDate = orderDate
        totalObj.storageInfo.orderTime = orderTime
        totalObj.storageInfo.paymentId = this.payment_id
      }
      totalObj.additionalInfo.paidInfo = '' // (this.getUrlPrices.skipPayment === 1) ? 'Not paid' : 'Paid'
      if (console) console.log('payment ' + totalObj.additionalInfo.paidInfo)

      function formatDate (date) {
        if (typeof date === 'string') {
          date = new Date(Date.parse(date))
        }
        return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      }

      try {
        await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER
          : 'http://localhost:3100'}/order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(totalObj)
        })
        this.$router.push({ name: 'AfterPayment' })
      } catch (err) {
        console.log('An error was occured ' + err.name)
      }
    }
  },
  watch: {
    email: {
      handler: function (val) {
        this.email = val.trim()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .info-wrapper {
    margin-bottom: 0;
    min-height: 100vh;
  }

  .payment-form {
    width: 100%;
    height: 100%;
    margin: 105px 0 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
  }

  .payment-form--content {
    width: 100%;
    max-width: 500px;
  }

  .payment-form-header {
    height: 100px;
    background: #e8e9eb;
    padding-top: 1em;
    border-bottom: 1px solid #cccccc;
  }

 .payment-form-header > h3 {
    font-family: BlissLight;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.1rem;
    color: #000000;
 }

 .payment-form-header > p {
    padding-top: 0.5em;
    font-family: BlissLight;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1rem;
    color: #53565A;
    text-align: center;
 }

 .payment-form-data {
   height: auto;
   padding: 40px 0px 50px;
   background: #f5f5f7;
 }

 .card-inputs-style {
    width: 80%;
    display: block;
    margin: auto;
    text-align: left;
    line-height: 3em;
    font-family: BlissLight;
    font-weight: bold;
    padding: 0 15px;
 }

  #card-number,
  #card-expiry,
  #card-cvc {
    padding: 1em 10px;
  }

  .card-elems-align form span input,
  .card-inputs-style form span input {
    font-size: 16px !important;
  }

 .payment-form-data > input::placeholder {
    font-size: 1em;
    padding: 10px;
  }

  .payment-form-data > input {
    font-size: 1em;
  }

  #card-number {
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

  .cards-block {
    width: 80%;
    display: block;
    margin: auto;
  }

  .card-elems-align {
    width: 50%;
    float: left;
    border: 1px solid #cccccc;
    border-radius: 5px;
    line-height: 3em;
    padding: 0 15px;
  }

  /* #card-number::before {
    position: relative;
    top: 30px;
    font-family: FontAwesome;
    font-weight: bold;
    font-size: 20px;
    color: #cccccc;
    content: "\f09d";
    cursor: pointer;
  } */

  .payment-remember-me {
    display: flex;
    width: 80%;
    height: 45px;
    /* display: block; */
    margin: 85px auto 0px auto;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-top: 15px;
    padding: 0 15px;
    align-items: center;
  }

  .payment-remember-me > input {
    width: 40px;
    display: block;
    float: left;
    box-shadow: none;
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    padding: 0;
    margin: 0;
  }

  .payment-remember-me > label {
    width: 70%;
    display: block;
    float: left;
    position: relative;
    font-family: BlissLight;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.1rem;
    color: #53565A;
  }

  .pay-btn {
    display: block;
    height: 45px;
    width: 80%;
    border: 1px solid #3da1de;
    border-radius: 5px;
    margin: 90px auto 40px;
    background: #3da1de;
    color: #ffffff;
    font-family: BlissLight;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }

  .pay-btn:disabled {
    background-color:rgba(#3da1de,.7);
    cursor: not-allowed;
  }

  .first-small {
    margin: 20px auto;
  }

  .expiry {
    margin: 20px 0px 20px -20px;
    display: inline-block;
  }

  .buttons-container {
    margin: 0;
  }
</style>
