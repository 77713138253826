<template>
  <div class="contact-us">
    <Popup :header='"Contact us"' />
  </div>
</template>

<script>
import Popup from '@/components/Popup'

export default {
  name: 'ContactUs',
  components: { Popup }
}
</script>

<style scoped lang="scss">
  .contact-us {
    width: 100%;
    max-height: 50px;
    background-color: #00a9e0;
    z-index: 9999;
  }
</style>
