<template>
  <div class="home">
    <Header :isBook='!isBook' :isArrival='!getStorageInfo.storageDateEnd ? true : false'/>
    <div class="info-wrapper">
      <div class="delivery-info">
        <div class="delivery-info--content">
          <div class="orange-box">Traveller Info</div>
          <h3 class="direction">
            <slot v-if="!getStorageInfo.storageDateEnd">{{ getOrderInfo.from }} to {{ getOrderInfo.to }}</slot>
            <slot v-if="getStorageInfo.storageDateEnd">Storage</slot>
          </h3>
          <div class='container-info'>
            <p>Full Name*</p>
            <input class="input_full-width" type="text" v-model="customerName" v-on:change="info" required><br><br>
            <p>Email*</p>
            <input class="m-top input_full-width" type="email" v-model="customerEmail"
                  v-on:change="info" @keyup="validateEmail(customerEmail)" required><br><br>
            <small v-if="!validEmail">
              Please enter correct email
            </small><br>
            <p>Country Code | Phone number*</p>
            <vue-phone-number-input class="phone-input m-top input_full-width"
                v-model="customerPhone" v-bind="phone" @input="info" :border-radius="10" @update="validPhoneF"></vue-phone-number-input>
            <small style="color: black">*The person must be one of the travellers</small><br>
          </div>
          <div class='container-info'>
            <p>Comments</p>
            <textarea class="input_full-width" rows='5' v-model="customerComment" v-on:change="info"></textarea>
          </div><br>
          <div class="buttons-container">
            <button
              v-if="(getOrderInfo.from === 'Hotel' || getOrderInfo.from === 'Cruise' || getOrderInfo.from === 'Private Address') && getOrderInfo.to === 'Airport'"
              class="btn-back"
              @click="$route.query.orderOnDock ? goToService('ArrivalInfo', service) : goTo('ArrivalInfo', service)">Back</button>
            <button
              v-else
              class="btn-back"
              @click="
                $route.query.orderOnDock ? goToService('DeliveryInfo', service) :
                getStorageInfo.storageDateEnd ?
                goTo('StorageInfo', service) :
                goTo('DeliveryInfo', service)"
            >Back</button>
            <button
              class="btn-next"
              :disabled="!customerName || !customerEmail || !validEmail || !phone.validPhone"
              @click="onNext()"
            >
              Next
            </button>

            <vue-element-loading
              :active="isLoading"
              spinner="spinner"
              color="#00a9e0"
            />
          </div>
        </div>
      </div>
      <BasketInfo :isArrival='!getStorageInfo.storageDateEnd ? true : false' class="basket-info-hidden" />
    </div>
    <Links link-location="row" />
    <ContactUs />
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import moment from 'moment-timezone'
import Header from '@/components/Header'
import additionalFunctions from '../mixins/additionalFunctions'
import Links from '@/components/TCAndPrivacyPolicyLinks'
import BasketInfo from '@/components/BasketInfo'
import ContactUs from '@/components/ContactUs'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TravellerInfo',

  props: ['service'],

  components: {
    Header,
    ContactUs,
    BasketInfo,
    Links,

    VueElementLoading
  },

  computed: {
    ...mapGetters([
      'getOrderInfo',
      'getStorageInfo',
      'getCustomerInfo',
      'getStateData',
      'priceCalculation',
      'getAllBagsCount',
      'getUrlPrices',
      'getStorageDays'
    ]),
    totalPrice () {
      if (!this.getStorageInfo.numberOfBags && !this.getStorageInfo.numberOfHeavyBags) {
        return Number(this.priceCalculation(true))
      }
      return Number(this.priceCalculation(false))
    }
  },

  mixins: [additionalFunctions],

  data () {
    return {
      isLoading: false,
      isBook: true,
      customerName: '',
      customerEmail: '',
      customerPhone: '',
      countryCode: '',
      validEmail: true,
      customerComment: '',
      phone: {
        clearable: true,
        fetchCountry: true,
        noExample: false,
        validPhone: false,
        translations: {
          countrySelectorLabel: 'Country code',
          countrySelectorError: 'Error',
          phoneNumberLabel: 'Enter your phone',
          example: 'Example:'
        }
      }
    }
  },

  created () {
    for (const key in this.getCustomerInfo) {
      if (key in this._data) {
        this[key] = this.getCustomerInfo[key]
      }
    }
  },

  mounted () {
    this.goToMainPage()
  },

  methods: {
    ...mapMutations(['addFieldsForCustomerInfo', 'goToMainPage', 'addFromTo']),
    validPhoneF (e) {
      this.phone.validPhone = e.isValid
    },
    info () {
      this.getCustomerInfo.customerCountryCode =
      document.querySelector('input[placeholder="Country code"]').value
      document.querySelector('input[placeholder="Country code"]').style.fontSize = '16px'
      document.querySelector('.input-tel__input').style.fontSize = '16px'

      for (const key in this.getCustomerInfo) {
        if (key in this.getCustomerInfo && (this[key] || this[key] === '')) {
          this.addFieldsForCustomerInfo({
            [key]: this[key]
          })
        }
      }
    },
    goTo (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    },
    async getInfoFromVuex () {
      this.isLoading = true

      let refValue = document.cookie.split(';') || []

      if (refValue.length) {
        refValue = refValue.find(c => c.includes('ref=')) || ''
        if (refValue) {
          refValue = refValue.split('=')[1]
        }
      }

      const dateNow = new Date()
      const objectMomentTimeZone = moment.tz('Europe/Copenhagen')
      const orderDate = objectMomentTimeZone.format('DD.MM.YYYY')
      const orderTime = objectMomentTimeZone.format('HH:mm:ss')
      const totalObj = {}

      totalObj.customerInfo = {
        ...this.getCustomerInfo,
        ref: refValue
      }
      totalObj.additionalInfo = this.getStateData.additionalInfo
      if (!this.getStorageInfo.numberOfBags && !this.getStorageInfo.numberOfHeavyBags) { // not storage
        totalObj.orderInfo = this.getOrderInfo
        totalObj.additionalInfo.priceInfo = this.priceCalculation(true)
        totalObj.additionalInfo.totalBags = this.getAllBagsCount(true)
        totalObj.orderInfo.arrivalDate = formatDate(this.getOrderInfo.arrivalDate)
        totalObj.orderInfo.orderDate = orderDate
        totalObj.orderInfo.orderTime = orderTime
        totalObj.orderInfo.orderChangedDate = Date.parse(dateNow)
        totalObj.orderInfo.paymentId = 'Without payment'
        if (console) console.log('****test = ')
        totalObj.orderInfo.noprintself = false
        totalObj.orderInfo.isOpen = true
      } else { // storage
        totalObj.storageInfo = this.getStorageInfo
        totalObj.additionalInfo.priceInfo = this.priceCalculation(false)
        totalObj.additionalInfo.totalBags = this.getAllBagsCount(false)
        totalObj.storageInfo.storageDateFrom = formatDate(this.getStorageInfo.storageDateFrom)
        totalObj.storageInfo.storageDateEnd = formatDate(this.getStorageInfo.storageDateEnd)
        totalObj.additionalInfo.storageDays = this.getStorageDays
        totalObj.storageInfo.orderDate = orderDate
        totalObj.storageInfo.orderTime = orderTime
        totalObj.storageInfo.paymentId = 'Without payment'
      }
      totalObj.additionalInfo.paidInfo = (this.getUrlPrices.skipPayment === 1) ? 'Not paid' : 'Paid'

      function formatDate (date) {
        if (typeof date === 'string') {
          date = new Date(Date.parse(date))
        }
        return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      }
      try {
        await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER
          : 'http://localhost:3100'}/order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(totalObj)
        })
        this.$router.push({ name: 'AfterPayment' })
      } catch (err) {
        console.log('An error was occured ' + err.name)
      } finally {
        this.isLoading = false
      }
    },
    onNext () {
      if (this.totalPrice < 1 || this.getUrlPrices.skipPayment === 1) {
        return this.getInfoFromVuex()
      }
      return this.$route.query.orderOnDock ? this.goToService('OrderDetails', this.service) : this.goTo('OrderDetails', this.service)
    }
  },

  watch: {
    customerEmail: {
      handler: function (val) {
        this.customerEmail = val.trim()
      }
    }
  }
}
</script>

<style>
  .country-selector__input {
    padding-right: 0 !important;
  }
</style>

<style scoped lang="scss">

  input {
    height: 40px;
  }

  .orange-box {
    font-family: BlissMedium;
    letter-spacing: 0.1em;
    font-size: 20px;
  }

  p {
    font-family: BlissMedium;
    letter-spacing: 0.1em;
  }

  .m-top, .home .input_full-width {
    margin-top: 2px;
  }

  .phone-input {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
  }

  input[type=radio] {
    width: auto;
    box-shadow: none;
    margin-right: 10px;
    width: 1.5em; height: 1.5em;
  }

  label {
    top: -5px;
    position: relative;
    font-family: BlissLight;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.1rem;
    color: #53565A;
  }

  textarea {
    width: 100%;
    border: 1px solid #53565A;
    border-radius: 10px;
    font-family: BlissLight;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    -webkit-box-shadow: inset 0px 0.5px 1px 0.5px #53565A;
    -moz-box-shadow: inset 0px 0.5px 1px 0.5px #53565A;
    box-shadow: inset 0px 0.5px 1px 0.5px #cccccc;
  }
</style>

<style>
  @media screen and (max-width: 390px) {
    .input-tel__label {
      display: none;
    }
  }

  .country-selector__input, .input-tel__input {
    border-color: #ccc !important;
  }
</style>
