var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$data[_vm.arrivalNumberId === 1 ? 'flightNumber' :
    _vm.arrivalNumberId === 0 ? 'flightNumber2' :
    (_vm.arrivalNumberId === 2 ? 'stateroom' : (_vm.arrivalNumberId === 3 ? 'hotelroom' : 'hotelName'))]),expression:"$data[arrivalNumberId === 1 ? 'flightNumber' :\n    arrivalNumberId === 0 ? 'flightNumber2' :\n    (arrivalNumberId === 2 ? 'stateroom' : (arrivalNumberId === 3 ? 'hotelroom' : 'hotelName'))]"}],staticClass:"input_full-width",class:_vm.isValidNumber ? '' : 'invalid',attrs:{"type":"text","placeholder":_vm.arrivalNumberId !== 2 && _vm.arrivalNumberId !== 3 && _vm.arrivalNumberId !== 4 ? 'e.g. DF4092' : ''},domProps:{"value":(_vm.$data[_vm.arrivalNumberId === 1 ? 'flightNumber' :
    _vm.arrivalNumberId === 0 ? 'flightNumber2' :
    (_vm.arrivalNumberId === 2 ? 'stateroom' : (_vm.arrivalNumberId === 3 ? 'hotelroom' : 'hotelName'))])},on:{"blur":function($event){_vm.arrivalNumberId === 1 ? _vm.validateArrivalNumber(_vm.flightNumber) :
    _vm.arrivalNumberId === 0 ? _vm.validateArrivalNumber(_vm.flightNumber2) :
    (_vm.arrivalNumberId === 2 ? _vm.validateArrivalNumber(_vm.stateroom) :
    (_vm.arrivalNumberId === 3 ? _vm.validateArrivalNumber(_vm.hotelroom) : _vm.validateArrivalNumber(_vm.hotelName)))},"change":_vm.number,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$data, _vm.arrivalNumberId === 1 ? 'flightNumber' :
    _vm.arrivalNumberId === 0 ? 'flightNumber2' :
    (_vm.arrivalNumberId === 2 ? 'stateroom' : (_vm.arrivalNumberId === 3 ? 'hotelroom' : 'hotelName')), $event.target.value)}}}),(_vm.isValidNumber ? '' : 'invalid')?_c('small',[_vm._v(_vm._s(_vm.validateFlightNumText))]):_vm._e(),_c('br')])}
var staticRenderFns = []

export { render, staticRenderFns }