export default {
  state: {
    constants: {
      MIN_PRICE: 38, // 38.85,
      MIN_PRICE_PRIVATE: 59,
      MIN_BAGS_TRANSFER: 3, // min bags corrsponding to min price
      VALUE1_AIRPORT: 12.95,
      VALUE1_PRIVATE: 19.95,
      VALUE1_STORAGE: 10, // Storage bag price per bag per night
      VALUE1_STORAGE2: 7, // Storage price if after x days (=VALUE1_DAYLIMIT) or more
      MIN_PRICE_STORAGE: 30, // min 3 bags
      MIN_BAGS_STORAGE: 3, // number of bags corrspinging to minimum price
      VALUE1_DAYLIMIT: 7, // value 1 is valid until this day included
      VALUE2: 8, // is this odd-size handling fee?
      LATE_FEE: 40,
      LATE_FEE_DAYS: -1, // How many days from now the late fee should be omitted
      MAX_BAGS: 20
    },
    orderInfo: {
      from: '',
      to: '',
      numberOfBags: 0,
      numberOfHeavyBags: 0,
      oddSize: '',
      arrivalDate: '',
      arrivalTime: '',
      flightNumber: '',
      hotelName: '',
      hotelroom: '',
      streetNumberPostal: '',
      apartment: '',
      cruiseShip: '',
      stateroom: '',
      services: ['Airport', 'Hotel', 'Cruise', 'Private Address']
    },
    storageInfo: {
      numberOfBags: 0,
      numberOfHeavyBags: 0,
      oddSize: '',
      flightNumber: '',
      flightNumber2: '',
      storageDateFrom: '',
      storageDateEnd: '',
      storageTimeFrom: '',
      storageTimeEnd: ''
    },
    customerInfo: {
      customerName: '',
      customerEmail: '',
      customerPhone: '',
      customerCountryCode: '',
      customerComment: ''
    },
    additionalInfo: {
      bagsPrice: '',
      bagsStoragePriceFirstNight: '',
      oddSizeBagsPrice: '',
      lateFee: 0,
      paidInfo: 'Paid' // assume payment is initialized, if skipPayment=1 then paid should be 0
    },
    isValidNumber: true,
    isValidDate: true,
    privacyPolicyContent: '',
    termsAndConditionsContent: '',
    closeAllPopups: false,
    storageDays: 1,
    queryPrices: {
      bagPrice: '',
      bagHeavyPrice: 0,
      stoPrice: '',
      skipPayment: 0, // 0 = do not skip, 1 = skip payment
      minimumPrice: 45,
      todayBookingAllowed: 0
    },
    paymentIntent: null
  },
  getters: {
    getState (state) {
      return state
    },
    getPaymentIntent (state) {
      return state.paymentIntent
    },
    getUrlPrices (state) {
      return state.queryPrices
    },
    getStorageDays (state) {
      return state.storageDays
    },
    getPrivacyPolicyContent (state) {
      return state.privacyPolicyContent
    },
    getTermsAndConditionsContent (state) {
      return state.termsAndConditionsContent
    },
    getOrderInfo (state) {
      return state.orderInfo
    },
    getStorageInfo (state) {
      return state.storageInfo
    },
    getCustomerInfo (state) {
      return state.customerInfo
    },
    getValidator (state) {
      return state
    },
    getStateData (state) {
      return state
    },
    getCloseAllPopups (state) {
      return state.closeAllPopups
    },
    getAllBagsCount (state) {
      return (type) => {
        if (type) {
          return parseInt(state.orderInfo.numberOfBags) + parseInt(state.orderInfo.numberOfHeavyBags)
        } else {
          return parseInt(state.storageInfo.numberOfBags) + parseInt(state.storageInfo.numberOfHeavyBags)
        }
      }
    },
    getDayLess: state => firstDate => {
      const CURRENT = new Date()
      let month = 0

      if (CURRENT.getMonth() < 10) {
        month = '0' + parseInt(CURRENT.getMonth() + 1)
      } else {
        month = parseInt(CURRENT.getMonth() + 1)
      }
      // eslint-disable-next-line
      const DATE = new Date((`${CURRENT.getUTCFullYear()}-${month}-${CURRENT.getUTCDate()}`).replace(/\-/g, '/')) // eslint-disable-next-line
      const calendarDate = new Date(firstDate.replace(/\-/g, '/'))
      const timeDiff = (calendarDate.getTime() - DATE.getTime())
      const diffDays = (timeDiff / (1000 * 3600 * 24))
      if (diffDays <= state.constants.LATE_FEE_DAYS) {
        return true
      }
    },
    priceCalculation (state, getters) {
      var tmpprice = 0
      return (type) => {
        if (state.queryPrices.bagPrice !== '' || state.queryPrices.bagHeavyPrice) {
          if (state.queryPrices.bagPrice >= 0) {
            state.additionalInfo.bagsPrice = Number(state.queryPrices.bagPrice * (state.orderInfo.numberOfBags + state.orderInfo.numberOfHeavyBags)).toFixed(2)
          } else {
            state.additionalInfo.bagsPrice = Number(state.constants.VALUE1_AIRPORT * (state.orderInfo.numberOfBags + state.orderInfo.numberOfHeavyBags)).toFixed(2)
          }
          if (state.queryPrices.bagHeavyPrice >= 0) {
            state.additionalInfo.oddSizeBagsPrice = Number(state.queryPrices.bagHeavyPrice * state.orderInfo.numberOfHeavyBags).toFixed(2)
          } else {
            state.additionalInfo.oddSizeBagsPrice = Number(state.constants.VALUE2 * state.orderInfo.numberOfHeavyBags).toFixed(2)
          }
          if (state.queryPrices.minimumPrice !== '') state.constants.MIN_PRICE = state.queryPrices.minimumPrice
          console.log('min pricexx = ' + state.constants.MIN_PRICE)
          tmpprice = (Number(state.additionalInfo.bagsPrice) + Number(state.additionalInfo.oddSizeBagsPrice)).toFixed(2)
          if (tmpprice < state.constants.MIN_PRICE && tmpprice > 0) tmpprice = state.constants.MIN_PRICE
          return tmpprice
        } else { // no query parameters used
          const BAGS = type ? parseInt(state.orderInfo.numberOfBags) : parseInt(state.storageInfo.numberOfBags)
          const HEAVY_BAGS = type ? parseInt(state.orderInfo.numberOfHeavyBags) : parseInt(state.storageInfo.numberOfHeavyBags)
          const ALL_BAGS_COUNT = type ? getters.getAllBagsCount(type) : getters.getAllBagsCount(type)
          const FIRST_DATE = type ? state.orderInfo.arrivalDate : state.storageInfo.storageDateFrom

          const storageFee = state.constants.VALUE1_STORAGE
          // const storageFee = state.queryPrices.stoPrice // state.constants.VALUE1_STORAGE
          const storageFeeLongterm = state.constants.VALUE1_STORAGE2
          const deliveryFee = (state.orderInfo.to === 'Private Address' || state.orderInfo.from === 'Private Address') ? state.constants.VALUE1_PRIVATE : state.constants.VALUE1_AIRPORT

          if (ALL_BAGS_COUNT > state.constants.MAX_BAGS) {
            alert('Please contact us for group offer and to find out the accurate cost of delivery')
          }
          let lateFee = 0
          if (getters.getDayLess(FIRST_DATE)) {
            lateFee = state.constants.LATE_FEE
          }

          // Below we calculate different price variations
          if (ALL_BAGS_COUNT === 0) return 0
          state.additionalInfo.bagsStoragePriceFirstNight = Math.max((storageFee * (BAGS + HEAVY_BAGS)).toFixed(2), state.constants.MIN_PRICE_STORAGE)
          if (!type) { // this is storage
            let stodays0 = 0
            let stodays1 = 0
            stodays0 = Math.min(state.storageDays, state.constants.VALUE1_DAYLIMIT)
            stodays1 = Math.max(0, state.storageDays - state.constants.VALUE1_DAYLIMIT) // to be used if discounted price after some days
            stodays0 -= 1 // first day price is = bagsStoragePriceFirstNight
            // state.additionalInfo.bagsPrice = (storageFee * (BAGS + HEAVY_BAGS) * state.storageDays).toFixed(2)
            state.additionalInfo.bagsPrice = (storageFee * stodays0.toFixed(2) + storageFeeLongterm * stodays1.toFixed(2)) * (BAGS + HEAVY_BAGS) + state.additionalInfo.bagsStoragePriceFirstNight
            state.additionalInfo.oddSizeBagsPrice = (state.constants.VALUE2 * HEAVY_BAGS).toFixed(2)
            state.additionalInfo.lateFee = lateFee
            // let minstoprice = 0
            // minstoprice = state.constants.MIN_PRICE_STORAGE
            tmpprice = state.additionalInfo.bagsPrice * 1 + state.additionalInfo.oddSizeBagsPrice * 1 + 1 * lateFee.toFixed(2)
            //  tmpprice += state.additionalInfo.bagsStoragePriceFirstNight
            // Ensure a min price
            // return Math.max(tmpprice, minstoprice) // (storageFee * (BAGS + HEAVY_BAGS) * state.storageDays + (state.constants.VALUE2 * HEAVY_BAGS) + lateFee).toFixed(2)
            return tmpprice
          } else { // not storage
            if (HEAVY_BAGS < 3 && ALL_BAGS_COUNT < 3) {
              if (state.orderInfo.to === 'Private Address' || state.orderInfo.from === 'Private Address') state.constants.MIN_PRICE = state.constants.MIN_PRICE_PRIVATE
              if (type) {
                state.additionalInfo.bagsPrice = state.constants.MIN_PRICE
                state.additionalInfo.oddSizeBagsPrice = (state.constants.VALUE2 * HEAVY_BAGS).toFixed(2)
                state.additionalInfo.lateFee = lateFee
                return (state.constants.MIN_PRICE + (state.constants.VALUE2 * HEAVY_BAGS) + lateFee).toFixed(2)
              }
            }

            if (BAGS === 1 && ALL_BAGS_COUNT < 3) {
              if (type) {
                return (state.constants.MIN_PRICE + lateFee).toFixed(2)
              }
            }
            if ((BAGS <= 3 && HEAVY_BAGS <= 3) || ALL_BAGS_COUNT > 3) {
              if (type) {
                state.additionalInfo.bagsPrice = (deliveryFee * (BAGS + HEAVY_BAGS)).toFixed(2)
                state.additionalInfo.oddSizeBagsPrice = (state.constants.VALUE2 * HEAVY_BAGS).toFixed(2)
                state.additionalInfo.lateFee = lateFee

                return (deliveryFee * (BAGS + HEAVY_BAGS) + (state.constants.VALUE2 * HEAVY_BAGS) + lateFee).toFixed(2)
              }
            }
          }
        }
      }
    }
  },
  mutations: {
    setState (state, localState) {
      Object.assign(state, localState)
    },
    changeMainInfo (state, mainQuery) {
      for (const key in mainQuery) {
        state.orderInfo[key] = mainQuery[key]
      }
    },
    changeTo (state, to) {
      state.orderInfo.to = to
    },
    changeHotelName (state, name) {
      state.orderInfo.hotelName = name
    },
    changeCruiseShip (state, name) {
      state.orderInfo.cruiseShip = name
    },
    changeApartment (state, name) {
      state.orderInfo.apartment = name
    },
    changeStreetNumberPostal (state, name) {
      state.orderInfo.streetNumberPostal = name
    },
    addNewPriceFromUrl (state, prices) {
      for (const key in prices) {
        // console.log('key value1 = ' + state.queryPrices.skipPayment)
        state.queryPrices[key] = Number(prices[key])
        // console.log('key value2 = ' + state.queryPrices.skipPayment)
        console.log('key value2 = ' + state.queryPrices.minimumPrice)
      }
    },
    addFromTo (state, fromTo) {
      if (fromTo.from) state.orderInfo.from = fromTo.from
      if (fromTo.to) state.orderInfo.to = fromTo.to
    },
    addTotalInfo (state, arrivalInfo) {
      if (arrivalInfo.numberOfBags > state.constants.MAX_BAGS || arrivalInfo.numberOfBags === '' ||
          isNaN(parseInt(arrivalInfo.numberOfBags)) || parseInt(arrivalInfo.numberOfBags) < 0) {
        arrivalInfo.isArrivalInfo ? state.orderInfo.numberOfBags = 0 : state.storageInfo.numberOfBags = 0
      } else {
        arrivalInfo.isArrivalInfo ? state.orderInfo.numberOfBags = arrivalInfo.numberOfBags
          : state.storageInfo.numberOfBags = arrivalInfo.numberOfBags
      }

      if (arrivalInfo.numberOfHeavyBags > state.constants.MAX_BAGS || arrivalInfo.numberOfHeavyBags === '' ||
          isNaN(parseInt(arrivalInfo.numberOfHeavyBags)) || parseInt(arrivalInfo.numberOfHeavyBags) < 0) {
        arrivalInfo.isArrivalInfo ? state.orderInfo.numberOfHeavyBags = 0 : state.storageInfo.numberOfHeavyBags = 0
      } else {
        arrivalInfo.isArrivalInfo ? state.orderInfo.numberOfHeavyBags = arrivalInfo.numberOfHeavyBags
          : state.storageInfo.numberOfHeavyBags = arrivalInfo.numberOfHeavyBags
      }

      arrivalInfo.isArrivalInfo ? state.orderInfo.oddSize = arrivalInfo.oddSize
        : state.storageInfo.oddSize = arrivalInfo.oddSize

      if (arrivalInfo.isArrivalInfo) {
        state.orderInfo.cruiseShip = arrivalInfo.cruiseShip
        state.orderInfo.streetNumberPostal = arrivalInfo.streetNumberPostal
        state.orderInfo.apartment = arrivalInfo.apartment
      }
    },
    addDate (state, date) {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December']

      const createDateFormat = (date) => {
        return monthNames[new Date(date).getUTCMonth()] + ' ' +
               new Date(date).getUTCDate() + ', ' + new Date(date).getUTCFullYear()
      }

      if ('arrivalDate' in date) {
        state.orderInfo.arrivalDate = createDateFormat(date.arrivalDate)
      }

      if ('storageDateFrom' in date) {
        state.storageInfo.storageDateFrom = createDateFormat(date.storageDateFrom)
      }

      if ('storageDateEnd' in date) {
        state.storageInfo.storageDateEnd = createDateFormat(date.storageDateEnd)
      }

      if (state.storageInfo.storageDateFrom && state.storageInfo.storageDateEnd) {
        if (!(new Date(state.storageInfo.storageDateEnd).getTime() >=
            new Date(state.storageInfo.storageDateFrom).getTime())) {
          state.storageInfo.storageDateEnd = ''
          document.querySelector('input[placeholder="Storage End (Date)*"]').value = ''

          alert('Storage End (Date) must be later than Storage From (Date)\nPlease choose the correct date')
        }
      }
    },
    addTime (state, time) {
      if ('arrivalTime' in time) {
        state.orderInfo.arrivalTime = time.arrivalTime
      }
      if ('storageTimeFrom' in time) {
        console.log('value in orderinfo: ' + time.storageTimeFrom)
        state.storageInfo.storageTimeFrom = time.storageTimeFrom
      }

      if ('storageTimeEnd' in time) {
        state.storageInfo.storageTimeEnd = time.storageTimeEnd
      }
    },
    addArrivalNumber (state, arrivalNumber) {
      for (const key in arrivalNumber) {
        if (key in arrivalNumber) {
          state.orderInfo[key] = arrivalNumber[key]
        }

        if (key === 'flightNumberStorage') {
          state.storageInfo.flightNumber = arrivalNumber[key]
        }

        if (key === 'flightNumberStorage2') {
          state.storageInfo.flightNumber2 = arrivalNumber[key]
        }
      }
    },
    addFieldsForDeliveryInfo (state, deliveryInfo) {
      for (const key in deliveryInfo) {
        if (key in deliveryInfo) {
          state.orderInfo[key] = deliveryInfo[key]
        }
      }
    },
    addFieldsForCustomerInfo (state, customerInfo) {
      for (const key in customerInfo) {
        if (key in customerInfo) {
          state.customerInfo[key] = customerInfo[key]
        }
      }
    },
    validator (state, dataStr) {
      if (typeof dataStr === 'boolean') {
        state.isValidNumber = dataStr
      } else {
        if (dataStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
          state.isValidDate = true
        } else {
          state.isValidDate = false
        }
      }
    },
    goToMainPage (state) {
      if (state.orderInfo.from === '' && state.storageInfo.numberOfBags === 0) {
        window.location.href = '/#service'
      }
    },
    setPrivacyPolicyContent (state, content) {
      state.privacyPolicyContent = content
    },
    setTermsAndConditionsContent (state, content) {
      state.termsAndConditionsContent = content
    },
    setCloseAllPopups (state, value) {
      state.closeAllPopups = value
    },
    setStorageDays (state, value) {
      state.storageDays = value
    },
    setPaymentIntent (state, id) {
      state.paymentIntent = id
    }
  },
  actions: {
  }
}
