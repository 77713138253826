var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('Header',{attrs:{"isBook":!_vm.isBook,"isArrival":!_vm.getStorageInfo.storageDateEnd ? true : false}}),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"delivery-info"},[_c('div',{staticClass:"delivery-info--content"},[_c('div',{staticClass:"orange-box"},[_vm._v("Delivery Info")]),_c('h3',{staticClass:"direction"},[(!_vm.getStorageInfo.numberOfBags)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.getOrderInfo.from)+" to "+_vm._s(_vm.getOrderInfo.to))]}):_vm._e(),(_vm.getStorageInfo.numberOfBags)?_vm._t("default",function(){return [_vm._v("Storage")]}):_vm._e()],2),_c('div',{staticClass:"container-info"},[(_vm.getOrderInfo.to !== 'Airport')?_c('br'):_vm._e(),(_vm.getOrderInfo.to !== 'Airport')?_c('Popup',{attrs:{"fieldName":(_vm.getOrderInfo.from === "Airport" && _vm.getOrderInfo.to === "Hotel") ? "Hotel name in City" :
              (_vm.getOrderInfo.to === "Hotel" ? "Hotel name in city" :
              (_vm.getOrderInfo.to === "Cruise" ? "Cruise ship name*" :
              (_vm.getOrderInfo.to === "Private Address" ? "Private address in city *" :
              (_vm.getStorageInfo.numberOfBags ? "Full Name *" : "")))),"header":_vm.getOrderInfo.to === "Cruise" ? "Cruise ship name" : "Some delivery info"}}):_vm._e(),(_vm.getOrderInfo.to !== 'Airport')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$data[_vm.getOrderInfo.to === 'Cruise' ? 'cruiseShip' :
              (_vm.getOrderInfo.to === 'Private Address' ? 'streetNumberPostal' : 'hotelName')]),expression:"$data[getOrderInfo.to === 'Cruise' ? 'cruiseShip' :\n              (getOrderInfo.to === 'Private Address' ? 'streetNumberPostal' : 'hotelName')]"}],staticClass:"input_full-width",attrs:{"type":"text","required":""},domProps:{"value":(_vm.$data[_vm.getOrderInfo.to === 'Cruise' ? 'cruiseShip' :
              (_vm.getOrderInfo.to === 'Private Address' ? 'streetNumberPostal' : 'hotelName')])},on:{"change":_vm.info,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$data, _vm.getOrderInfo.to === 'Cruise' ? 'cruiseShip' :
              (_vm.getOrderInfo.to === 'Private Address' ? 'streetNumberPostal' : 'hotelName'), $event.target.value)}}}):_vm._e(),((_vm.getOrderInfo.to === 'Cruise' || _vm.getOrderInfo.to === 'Hotel' || _vm.getOrderInfo.to === 'Airport'))?_vm._t("default",function(){return [_c('br'),_c('br'),_c('p',[_vm._v(" "+_vm._s(_vm.getOrderInfo.to === 'Airport' ? "" : (_vm.getOrderInfo.to === 'Cruise' ? 'Stateroom (if known)' : ''))+" ")]),(_vm.getOrderInfo.to === 'Airport')?_c('Popup',{attrs:{"fieldName":_vm.getOrderInfo.to === "Airport" ? "Flight number (if known)" : "","header":"Some delivery info"}}):_vm._e(),(_vm.getOrderInfo.to !== 'Hotel')?_c('ArrivalNumber',{attrs:{"arrivalNumberId":_vm.getOrderInfo.to === "Airport" ? 1 :(_vm.getOrderInfo.to === "Cruise" ? 2 : 3),"from":"ArrivalInfo"}}):_vm._e()]}):_vm._e(),((_vm.getOrderInfo.to === 'Private Address'))?_vm._t("default",function(){return [_c('br'),_c('br'),_c('p',[_vm._v("Apartment identification")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.apartment),expression:"apartment"}],staticClass:"input_full-width",attrs:{"type":"text","placeholder":"e.g. name on door, floor, …","required":""},domProps:{"value":(_vm.apartment)},on:{"change":_vm.info,"input":function($event){if($event.target.composing){ return; }_vm.apartment=$event.target.value}}}),_c('br')]}):_vm._e()],2),_c('br'),_vm._m(0),_c('br'),_c('div',{staticClass:"buttons-container"},[_c('button',{on:{"click":function($event){_vm.$route.query.orderOnDock ? _vm.goToService('ArrivalInfo', _vm.service) :
                  !_vm.getStorageInfo.numberOfBags ? _vm.goTo('ArrivalInfo', _vm.service) :
                  _vm.goTo('StorageInfo', _vm.service)}}},[_vm._v("Back")]),_c('button',{staticClass:"btn-next",attrs:{"disabled":(_vm.getOrderInfo.to === 'Cruise' ? !_vm.cruiseShip :
                  (_vm.getOrderInfo.to === 'Private Address' ? !_vm.apartment && !_vm.streetNumberPostal :
                  (_vm.getOrderInfo.to === 'Hotel' || _vm.getStorageInfo.numberOfBags ? !_vm.hotelName :
                  (_vm.getOrderInfo.to === 'Airport' ? !_vm.getValidator.isValidNumber : ''))))},on:{"click":function($event){_vm.$route.query.orderOnDock ? _vm.goToService('TravellerInfo', _vm.service) : _vm.goTo('TravellerInfo', _vm.service)}}},[_vm._v("Next")])])])]),_c('BasketInfo',{staticClass:"basket-info-hidden",attrs:{"isArrival":!_vm.getStorageInfo.storageDateEnd ? true : false}})],1),_c('Links',{attrs:{"link-location":"row"}}),_c('ContactUs')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("../assets/images/we_deliver.png")}})])}]

export { render, staticRenderFns }