<template>
  <div class="home">
    <Header/>

    <ChangeOrderInfo
      v-if="changeOrderInfo"
      @closeChanges="closeOrderInfoChanges"
      :orderInfo="orderInfo"
      :customerInfo="customerInfo"
    />
    <div v-if="!changeOrderInfo" class="customer-order">
      <h2>YOUR ORDER:</h2>
      <h2>{{ orderInfo.orderNumber }}</h2>
      <h2
        v-if  = "orderInfo.isOpen === false"
        class = "customer-order__closed-order"
      >
        ORDER CLOSED
      </h2>
      <div class="customer-order-dropdowns">

        <div class="customer-order-dropdowns__order-info">
          <div class="customer-order-dropdowns__title" @click="orderInfo.isOpen === false ? false : orderInfoOpened = !orderInfoOpened">
            <!-- <span v-if="orderInfo.from === 'Airport'">{{ orderInfo.arrivalDate }}: Airport to Hotel</span> -->
            <span v-if="orderInfo.from === 'Cruise'">{{ orderInfo.arrivalDate }}: Cruise to {{ orderInfo.to }}</span>
            <!-- <span v-if="isStorage">{{ orderInfo.storageDateFrom }}: Storage({{ storageDays }})</span> -->
            <div
              class="customer-order-dropdowns__icon-lines"
              :class="{'customer-order-dropdowns__icon-lines--opened': orderInfoOpened}"
            >
              <div></div><div></div>
            </div>
          </div>

          <div v-if="orderInfoOpened" class="customer-order-dropdowns__content">
            <div v-if="!isStorage">
              <h3>ORDER <span> {{ orderInfo.isOpen ? 'IN PROGRESS' : 'CLOSED' }} </span></h3>
              <span>
                {{ orderInfo.numberOfBags }} bags
                <span v-if="orderInfo.numberOfHeavyBags > 0">
                  + {{ orderInfo.numberOfHeavyBags }} odd-size
                </span><span v-if="orderInfo.oddSize">, {{ orderInfo.oddSize }}</span>
                <br>
                <span>Arrive {{ formatingDate() }}</span>
                <span v-if="orderInfo.flightNumber">Flight {{ orderInfo.flightNumber }}</span><br><br>
              </span>
              <!-- <h3>Customs:</h3>
              <span>CPH Luggage Service will take your bags from belt throught Customs</span><br><br> -->
              <h3>Deliver to: </h3>
              <span>{{ orderInfo.to }}: {{ getAddress() }}</span><br><br>
              <h3>Change your order: <button @click="changeOrderInfo = true">CONTACT US</button></h3>
            </div>
            <div v-else>
              <h3>STORAGE SERVICE</h3>
              <span>
                Storage of {{ orderInfo.numberOfBags }} bags
                <span v-if="orderInfo.numberOfHeavyBags > 0">
                  + {{ orderInfo.numberOfHeavyBags }} odd-size<br>Description: {{ orderInfo.oddSize }}
                </span><br>
                <span v-if="orderInfo.flightNumber">
                  <strong>Arrival Flight </strong>{{ orderInfo.flightNumber }}
                </span>
              </span><br><br>
              <h3>Storage Start:</h3>
              <span>{{ orderInfo.storageDateFrom }}, {{ orderInfo.storageTimeFrom }}</span><br><br>
              <h3>Storage End:</h3>
              <span>{{ orderInfo.storageDateEnd }}, {{ orderInfo.storageTimeEnd }}</span><br><br>
              <h3>Change your order: <button @click="changeOrderInfo = true">CONTACT US</button></h3>
            </div>
          </div>
        </div>

        <div v-if="!isStorage" class="customer-order-dropdowns__luggage-photos">
          <div class="customer-order-dropdowns__title" @click="orderInfo.isOpen === false ? false : luggagePhotosOpened = !luggagePhotosOpened">
            <span>Luggage info & Photos</span>
            <div
              class="customer-order-dropdowns__icon-lines"
              :class="{'customer-order-dropdowns__icon-lines--opened': luggagePhotosOpened}"
            >
              <div></div><div></div>
            </div>
          </div>
          <LuggageInfoAndPhotos
            :opened="luggagePhotosOpened"
            :orderInfo="orderInfo"
            :customerInfo="customerInfo"
            :key="LuggageInfoAndPhotosKay"
            @arrayUrlPhotosGet='arrayUrlPhotosGet'
          />
        </div>

        <!-- <div v-if="!isStorage" class="customer-order-dropdowns__order-status">
          <div class="customer-order-dropdowns__title" @click="orderStatusOpened = !orderStatusOpened">
            <span>Order Status</span>
            <div
              class="customer-order-dropdowns__icon-lines"
              :class="{'customer-order-dropdowns__icon-lines--opened': orderStatusOpened}"
            >
              <div></div><div></div>
            </div>
          </div>
          <div v-if="orderStatusOpened" class="customer-order-dropdowns__content"></div>
        </div> -->

        <div class="customer-order-dropdowns__customer-info">
          <div class="customer-order-dropdowns__title" @click="orderInfo.isOpen === false ? false : customerInfoOpened = !customerInfoOpened">
            <span>Customer Information</span>
            <div
              class="customer-order-dropdowns__icon-lines"
              :class="{'customer-order-dropdowns__icon-lines--opened': customerInfoOpened}"
            >
              <div></div><div></div>
            </div>
          </div>
          <div v-if="customerInfoOpened" class="customer-order-dropdowns__content">
            <h3>Customer name:</h3>
            <span>{{ customerInfo.customerName }}</span><br><br>
            <h3>Customer email:</h3>
            <span>{{ customerInfo.customerEmail }}</span><br><br>
            <h3>Customer phone:</h3>
            <span>{{ customerInfo.customerCountryCode }} {{ customerInfo.customerPhone }}</span><br><br>
            <h3>Orders:</h3>
            <span>{{ orderInfo.orderNumber }}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import ChangeOrderInfo from '@/components/CustomerOrders/ChangeOrderInfo'
import LuggageInfoAndPhotos from '@/components/CustomerOrders/LuggageInfoAndPhotos'

export default {
  components: {
    Header,
    ChangeOrderInfo,
    LuggageInfoAndPhotos
  },
  data: () => ({
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    orderInfoOpened: false,
    luggagePhotosOpened: false,
    orderStatusOpened: false,
    customerInfoOpened: false,
    changeOrderInfo: false,
    orderNum: '',
    customerInfo: {},
    orderInfo: {},
    isStorage: false,
    storageDays: 0,
    LuggageInfoAndPhotosKay: 0
  }),
  created () {
    if (this.$route.query.userID) {
      this.userID = this.$route.query.userID
      this.getData()
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    async arrayUrlPhotosGet () {
      await this.getData()
      // this.LuggageInfoAndPhotosKay += 1
      // this.$forceUpdate()
    },
    closeOrderInfoChanges () {
      this.changeOrderInfo = false
      this.orderInfoOpened = true
    },
    async getData () {
      try {
        const response = await fetch(
        `${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER
          : 'http://localhost:3100'}/get-order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userID: this.userID
          })
        })
        const result = await response.json()
        this.customerInfo = result.customerInfo[0]
        this.orderInfo = result.orderInfo[0]
        this.isStorage = !!result.orderInfo[0].storageDateFrom
        if (this.isStorage) {
          const dayFrom = Math.floor(Date.parse(this.orderInfo.storageDateFrom) / 86400000)
          const dayEnd = Math.floor(Date.parse(this.orderInfo.storageDateEnd) / 86400000)
          const storageDays = dayEnd - dayFrom
          this.storageDays = storageDays > 1 ? `${storageDays} days` : '1 day'
        }
        this.formatingDate()
        // console.log('9090909009090909')
        // console.log(this.orderInfo.imgUrl)
      } catch (err) {
        console.log(err)
        this.$router.push('/')
      }
    },
    formatingDate () {
      const numberOfMonth = this.orderInfo.arrivalDate.split('.')[1].split('')[1]
      const day = this.orderInfo.arrivalDate.split('.')[0]
      const year = this.orderInfo.arrivalDate.split('.')[2]
      return `${this.monthNames[numberOfMonth - 1]} ${day}, ${year}`
    },
    getAddress () {
      if (this.orderInfo.to === 'Hotel') {
        return `${this.orderInfo.hotelName}`
      } else if (this.orderInfo.to === 'Airport') {
        return `${this.orderInfo.arrivalTime}, ${this.formatingDate()}`
      } else if (this.orderInfo.to === 'Private Address') {
        return `${this.orderInfo.streetNumberPostal} ${this.orderInfo.apartment}`
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/customerOrder";
</style>
