<template>
  <div>
    <p>Read more about our services</p>
    <div class="flex-div">
      <div class="flex-div--content">
        <div class="flex-img" @click="modalControl(true, 'plane')">
          <img src="../assets/images/read_more/plane.png">
        </div>
        <div class="flex-img" @click="modalControl(true, 'cruise')">
          <img class="m-bottom" src="../assets/images/read_more/cruise.png">
        </div>
        <div class="flex-img" @click="modalControl(true, 'storage')">
          <img src="../assets/images/read_more/luggage.png">
        </div>
      </div>
    </div>
    <transition name="fade" appear>
      <div class="modal-overlay" v-if="showModal" @click="modalControl(false)"></div>
    </transition>
    <transition name="slide" appear>
      <div class="modal" v-if="showModal">
        <span class="close-btn" @click="modalControl(false)">X</span>
        <h2>{{ h2 }}</h2>
        <img v-bind:src='img'>
        <h3>{{ desc }}</h3>
        <slot v-if="isPlane">
          <Divider />
          <img src="../assets/images/read_more/landing.png">
          <h2>Arrival service to your hotel or cruise ship</h2><br>
          <h3>Have your bags transferred from the airport to your accommodation or cruise ship.</h3><br>
          <h3>We can take the bags directly from the belt at arrival or you can deliver them to our office outside Customs.</h3>
          <Divider />
          <img src="../assets/images/read_more/departure.png">
          <h2>Departure service from hotel or cruise ship</h2><br>
          <h3>Have your bags transferred from your accommodation / hotel or cruise ship to the airport.</h3><br>
          <h3>We also offer VIP airport check-in service from your hotel / cruise and take care of luggage delivery to your airline.</h3>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Divider from '@/components/Divider'

export default {
  name: 'ReadMore',
  components: { Divider },
  data () {
    return {
      showModal: '',
      isPlane: false,
      h2: '',
      img: '',
      desc: ''
    }
  },
  computed: {
    ...mapGetters(['getCloseAllPopups']),
    isSafari () {
      return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification))
    }
  },
  methods: {
    getImgUrl (img) {
      return require('../assets/images/read_more/' + img)
    },
    modalControl (control, service) {
      this.showModal = control
      if (control) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
      switch (service) {
        case 'plane':
          this.isPlane = true
          this.h2 = 'Airport Service'
          this.img = this.getImgUrl('plane_popup.png')
          this.desc = `Airport Services includes luggage transport to and from CPH Airport -
                       as well as bag pick-up at baggage claim belt or at our office in the airport.`
          break
        case 'cruise':
          this.isPlane = false
          this.h2 = 'City Porter Service'
          this.img = this.getImgUrl('cruise.png')
          this.desc = `City Porter Services include all luggage transport to and from cruise ships
                       at Copenhagen Port`
          break
        case 'storage':
          this.isPlane = false
          this.h2 = 'Storage Service'
          this.img = this.getImgUrl('luggage.png')
          this.desc = `CPH Luggage Service provides storage service at Copenhagen Airport. 
                       We can store most kinds of luggage - also the odd-size which doesn't fit locker systems.
                       Combine it with luggage transfer on arrival and departure days
                       and have more time to enjoy your stay at Copenhagen.`
          break
        default:
          break
      }
    }
  },
  watch: {
    showModal (newVal, oldVal) {
      if (newVal) {
        document.querySelector('html').style.overflow = 'hidden'
      } else {
        document.querySelector('html').style.overflow = 'auto'
      }
    },
    getCloseAllPopups (newVal, oldVal) {
      if (newVal === true) {
        this.showModal = false
      }
    }
  }
}
</script>

<style>
  body.modal-open {
    overflow: hidden;
  }
</style>

<style scoped lang="scss">
  @import "../assets/scss/popup";

  div p {
    padding: 0em 2em;
    font-family: BlissLight;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    text-align: center;
    text-transform: uppercase;
  }

  .flex-div {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .flex-div--content {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .flex-img {
    width: 2em;
    flex: 25%;
    margin: 2em 2em;
    background: #f2f2f2;
    border: 2px solid #878787;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.3);
    transition: 0.4s ease-out;
    border-radius: 15px;
    cursor: pointer;
  }

  .flex-img--content img {
    width: 3em;
  }

  .m-bottom {
    margin-bottom: 3px !important;
  }

  @media screen and (max-width: 860px) {
    .flex-img {
      margin: 2em 1em;
    }

    .flex-img img {
      width: 3.5em;
    }
  }

  @media screen and (max-width: 600px) {
    .flex-img {
      margin: 2em 0.2em;
      padding: 1em 3em 0em 3.5em;
      height: 5em;
    }

    .flex-img img {
      width: 3em;
      margin: 0em 0.5em;
    }
  }

  @media screen and (max-width: 540px) {
    .flex-img {
      margin: 2em 1em;
      padding: 1em 1em 0em 2.3em;
    }
  }

  @media screen and (max-width: 515px) {
    .flex-img {
      margin: 2em 1em;
      padding: 1em 1em 0em 2em;
    }
  }

  @media screen and (max-width: 470px) {
    .flex-img {
      margin: 2em 0.3em;
      padding: 1em 3em 0em 1.7em;
    }
  }

  @media screen and (max-width: 375px) {
    .flex-img {
      padding: 1em 3em 0em 1.3em;
    }
  }

  @media screen and (max-width: 320px) {
    .flex-img {
      padding: 1em 3em 0em 1.1em;
    }
  }

  @media screen and (max-width: 320px) {
    .flex-img img {
      margin: 0em 0.1em;
    }
  }

  @media screen and (max-width: 280px) {
    .flex-img img {
      width: 2.5em;
      margin: 0em -0.2em;
    }
  }
</style>
