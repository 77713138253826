<template>
  <div class="home">
    <Header :isBook='isBook' :isArrival='false'/>
    <div class="intro">
      <div class="select_service service-screen">
        <div class="select">Luggage transport</div>
        <div class="service">
          <div class="service-title service-margin-title">From</div>
          <div class="service-btn service-content-m2 servie-img-btn"
               v-bind:value="serviceNameFrom" v-on:click="isHiddenFrom = !isHiddenFrom">
               {{ serviceNameFrom ? serviceNameFrom :  getOrderInfo.from }}
               <div class="arrow" v-bind:class="{is_open: !isHiddenFrom}">&gt;</div>
          </div>
          <div v-for="dest in services" :key="dest" class="service-content">
            <slot>
              <a v-on:click.prevent @click="addToService(dest, 'from')" v-on:click="isHiddenFrom = true"
                 v-if="!isHiddenFrom">- {{ dest }}</a>
            </slot>
          </div>
          <div v-bind:class="{ opacity: serviceNameFrom }" class="zero-opacity service-title service-margin-title">To</div>
          <div v-bind:class="{ opacity: serviceNameFrom }" class="zero-opacity service-btn service-btn-t servie-img-btn"
               v-bind:value="serviceNameTo" v-on:click="isHiddenTo = !isHiddenTo">
            {{ getOrderInfo.to ? getOrderInfo.to :  'Select destination' }}
            <div class="arrow" v-bind:class="{is_open: !isHiddenTo}">&gt;</div>
          </div>
          <div v-if="serviceNameFrom">
            <div v-for="dest in services" :key="dest + '-value'" class="service-content service-content-m">
              <slot v-if="getOrderInfo.from === 'Hotel' && dest === 'Private Address' && !isHiddenTo">
                <a class="to-disabled">- {{ dest }}</a>
              </slot>
              <slot v-else-if="getOrderInfo.from === 'Private Address' && dest === 'Hotel' && !isHiddenTo">
                <a class="to-disabled">- {{ dest }}</a>
              </slot>
              <slot v-else-if="getOrderInfo.from === dest && !isHiddenTo">
                <a class="to-disabled">- {{ dest }}</a>
              </slot>
              <slot v-else>
                <a @click="addToService(dest, 'to')" v-on:click="isHiddenTo = true"
                  v-if="dest !== getOrderInfo.from && !isHiddenTo">- {{ dest }}</a>
              </slot>
            </div>
          </div>
        </div>
        <div class="buttons-container">
          <button class="btns btn-first-back" onclick="location.href='/#service'">Back</button>
          <button class="btns btn-first-next" :disabled="!getOrderInfo.to"
                  @click="$route.query.orderOnDock ? goToService('ArrivalInfo', getOrderInfo.from) : goTo('ArrivalInfo', getOrderInfo.from)">Next</button>
        </div>
      </div>
      <ReadMore />
    </div>
    <Links link-location="row" />
    <ContactUs />
  </div>
</template>

<script>
import additionalFunctions from '../mixins/additionalFunctions'
import Header from '@/components/Header'
import ReadMore from '@/components/ReadMore'
import Links from '@/components/TCAndPrivacyPolicyLinks'
import ContactUs from '@/components/ContactUs'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Service',
  props: ['service', 'services'],
  components: {
    Header,
    ReadMore,
    ContactUs,
    Links
  },
  computed: mapGetters(['getOrderInfo', 'getStateData']),
  mixins: [additionalFunctions],
  data () {
    return {
      serviceNameFrom: '',
      serviceNameTo: '',
      isBook: true,
      isHiddenFrom: false,
      isHiddenTo: false
    }
  },
  mounted () {
    this.goToMainPage()

    // this.service = this.$route.service || this.getOrderInfo.from
    this.services = this.$route.services || this.getOrderInfo.services

    // this.serviceNameFrom = this.getOrderInfo.from
    this.serviceNameTo = this.getOrderInfo.to

    this.clearStateObjects(this.getOrderInfo)
    this.clearStateObjects(this.getStateData.additionalInfo)
  },
  watch: {
    serviceNameFrom: {
      handler: function (val) {
        // console.log('cka: ' + val)
        this.serviceNameTo = ''
        // this.orderInfo.to = ''
      }
    }
  },
  methods: {
    ...mapMutations(['addFromTo', 'goToMainPage', 'changeTo']),
    addToService (to, dest) {
      if (dest === 'from') {
        this.serviceNameFrom = to
        this.serviceNameTo = ''
        this.changeTo('')
      } else {
        this.serviceNameTo = to
      }

      this.addFromTo({
        from: this.serviceNameFrom ? this.serviceNameFrom : this.service,
        to: this.serviceNameTo
      })
    },
    goTo (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    },
    clearStateObjects (object) {
      Object.keys(object).map(orderElem => {
        if (orderElem in object && (orderElem !== 'from' && orderElem !== 'to' && orderElem !== 'services')) {
          if (orderElem === 'numberOfBags' || orderElem === 'numberOfHeavyBags') {
            object[orderElem] = 0
          } else {
            object[orderElem] = ''
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .home {
    animation: fadeIn .5s;
    transition: all .5s ease-in-out;
    -webkit-animation: fadeIn .5s;
  }

  .zero-opacity {
    opacity: 0;
  }

  .opacity {
    opacity: 1;
  }

  .service-btn {
    height: auto;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.05em;
  }

  .servie-img-btn {
    margin: 0em 1.6em 0 1.6em;
    padding: 0.5em 0;
  }

  .service-btn.servie-img-btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  .service-btn-t {
    margin-bottom: 0.7em;
  }

  .service-margin-title {
    margin-left: 1.6em;
    font-family: BlissMedium;
  }

  // .service-btn:hover .arrow {
  //   transform: rotateZ(-90deg);
  // }

  .service-btn-p {
    padding: 0.5em 1em 0.5em 1em;
  }

  .service-content-m {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  // .service-screen .service-content:nth-child(5), .service-content-m2 {
  //   margin-bottom: 10px;
  // }

  .service-from {
    border-radius: 0px;
  }

  .buttons-container {
    width: 100%;
    max-height: 95px;
    display: inline-block;
    max-height: 50px;
  }

  .to-disabled {
    background: #ff6666 !important
  }

  .service-content-m .to-disabled {
    display: none;
  }

  .buttons-container button {
    border: solid #cccccc;
    border-width: 0px 0.5px 2px 0.5px;
    width: 5em;
    height: 2em;
    color: #fff;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.1rem;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #FC9000;
  }

  .buttons-container .btn-first-back {
    float: left;
    margin: 0em 1.8em 1.5em 1.4em;
  }

  .buttons-container .btn-first-next {
    float: right;
    margin: 0em 1.4em 1.5em 1.7em;
  }

  @media screen and (max-width: 374px) {
    .buttons-container .btn-first-next {
      float: right;
      margin: 0em 1.2em 1.5em 0em;
    }
  }

  @media screen and (max-width: 344px) {
    .buttons-container .btn-first-back {
      float: left;
      margin: 0em 0em 1.5em 1.2em;
    }
  }

  @media screen and (max-width: 505px) {
    .select_service {
      margin-bottom: 20px;
    }
  }

  button[disabled] {
    background-color: rgba(252, 144, 0, .7);
  }
</style>
