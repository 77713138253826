<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = 'Copenhagen Luggage service'
      }
    },
    '$route.query': {
      handler (query) {
        if (!document.cookie.includes('ref=') && query.clsref) {
          const expires = (new Date(Date.now() + 86400 * 21000)).toUTCString() // 21 day
          document.cookie = `ref=${query.clsref}; expires=${expires}; path=/;`
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['setState'])
  },
  created () {
    if (['TermsAndConditions', 'AfterPayment'].includes(this.$route.name)) {
      if (localStorage.getItem('state')) {
        this.setState(JSON.parse(localStorage.state))
        localStorage.removeItem('state')
      }
    } else {
      localStorage.removeItem('state')
    }
  }
}
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #app {
    position: relative;
  }

  select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] { font-size: 16px !important; background-color: white; }
</style>
