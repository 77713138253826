<template>
  <div class="home">
    <Header :isBook='!isBook' :isArrival='!getStorageInfo.storageDateEnd ? true : false'/>
    <div class="info-wrapper">
      <div class="delivery-info">
        <div class="delivery-info--content">
          <div class="orange-box">Order Details</div>
          <div class='container-info'>
            <br>
            <p>{{ getStorageInfo.storageDateEnd ? 'Storage' : getOrderInfo.from }} Service</p>
            <hr class="hr"><br>
            <div>
              <div class="block-left">
                <p>{{ !this.getStorageInfo.storageDateEnd ? this.getAllBagsCount(true) : this.getAllBagsCount(false) }}
                  bag(s):
                </p>
              </div>
              <div class="block-right">
                <p>{{ getStateData.additionalInfo.bagsPrice }}</p>
              </div>
            </div><br>
            <div class="m-top">
              <div class="block-left">
                <p>{{ getStorageInfo.numberOfHeavyBags > 0 ?
                      getStorageInfo.numberOfHeavyBags : getOrderInfo.numberOfHeavyBags }} Odd-size:</p>
              </div>
              <div class="block-right">
                <p>{{ getStateData.additionalInfo.oddSizeBagsPrice }}</p>
              </div>
            </div>
            <div v-if="getStateData.additionalInfo.lateFee"><br><br>
              <div class="block-left">
                <p>later order fee for:</p>
              </div>
              <div class="block-right">
                <p>{{ getStateData.additionalInfo.lateFee.toFixed(2) }}</p>
              </div><br><br>
            </div>
            <hr class="hr"><br>
            <div>
              <div class="block-left"><p>Total:</p></div>
              <div class="block-right">
                <p>EUR {{ priceCalculation(!getStorageInfo.storageDateEnd ? true : false) }}</p>
              </div>
            </div><br>
          </div><br>
          <div><img src="../assets/images/transport_back.png"></div><br>
          <div class="buttons-container">
            <button class="btn-back" @click="$route.query.orderOnDock ? goToService('TravellerInfo', service) : goTo('TravellerInfo', service)">Back</button>
            <button class="btn-next" @click="$route.query.orderOnDock ? goToService('TermsAndConditions', service) : goTo('TermsAndConditions', service)">Pay</button>
          </div>
        </div>
      </div>
      <BasketInfo :isArrival='!getStorageInfo.storageDateEnd ? true : false' class="basket-info-hidden" />
    </div>
    <Links link-location="row" />
    <ContactUs />
  </div>
</template>

<script>
import additionalFunctions from '../mixins/additionalFunctions'
import Header from '@/components/Header'
import BasketInfo from '@/components/BasketInfo'
import Links from '@/components/TCAndPrivacyPolicyLinks'
import ContactUs from '@/components/ContactUs'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OrderDetails',
  props: ['service'],
  components: {
    Header,
    ContactUs,
    BasketInfo,
    Links
  },
  computed: mapGetters([
    'getOrderInfo', 'getStorageInfo', 'getStateData', 'getAllBagsCount',
    'priceCalculation'
  ]),
  mixins: [additionalFunctions],
  data () {
    return {
      isBook: true
    }
  },
  mounted () {
    this.goToMainPage()
  },
  methods: {
    ...mapMutations(['goToMainPage', 'addFromTo']),
    goTo (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  input {
    height: 40px;
  }
  p {
    margin-left: 40px;
    font-family: BlissLight;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.07rem;
    color: #53565A;
  }

  .hr {
    margin: 30px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 80%;
  }

  @media screen and (min-width: 1023px) {
    img {
      width: 40%;
    }
  }

  .block-left {
    width: 50%;
    float: left;
    margin-top: -20px;
  }

  .block-right {
    width: 50%;
    float: right;
    margin-top: -20px;
  }
</style>
