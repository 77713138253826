import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VBodyScrollLock from 'v-body-scroll-lock'

import 'jquery-ui/themes/base/datepicker.css'
import 'jquery-ui/themes/base/theme.css'

import VueGtm from '@gtm-support/vue2-gtm'

import { func } from './mixins/globalfunctions'
Vue.prototype.$func = func

require('jquery-ui/ui/widgets/datepicker.js')

Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.config.productionTip = false

Vue.use(VBodyScrollLock)

Vue.use(VueGtm, {
  id: 'GTM-KDW795T3',
  vueRouter: router,
  enabled: true,
  debug: true,
  loadScript: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Heroku-24 stack
